import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";
import { Stack, Typography } from "@mui/material";
import { Claim, ClaimActivityValues, ClaimStatus } from "../../utils/types";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

interface CustomTimelineItemProps {
  label: ClaimActivityValues;
  Icon: React.ElementType;
  selectedClaim: Claim;
  isLastItem?: boolean;
}

const enum StatusLabel {
  APPROVED = "Approved",
  PENDING = "Pending",
  REJECTED = "Rejected",
}

export const CustomTimelineItem: React.FC<CustomTimelineItemProps> = ({ label, Icon, selectedClaim, isLastItem }) => {
  const claimStatus = selectedClaim.statusDetails.status;
  let date: string | null;
  let rejectedReason: string | null = null;

  const [statusColor, setStatusColor] = useState<{ bgcolor: string; color: string; borderColor: string }>();
  const [statusLabel, setStatusLabel] = useState<StatusLabel>();

  switch (label) {
    case ClaimActivityValues.CLAIM_SUBMISSION: {
      date = selectedClaim.createdDate;
      break;
    }
    case ClaimActivityValues.LEAD_REVIEW: {
      date = selectedClaim.statusDetails.leadApprovedDate || selectedClaim.statusDetails.leadRejectedDate;
      rejectedReason = selectedClaim.statusDetails.leadRejectedReason ?? null;
      break;
    }
    default: {
      date = selectedClaim.statusDetails.financeApprovedDate || selectedClaim.statusDetails.financeRejectedDate;
      break;
    }
  }

  useEffect(() => {
    if (label === ClaimActivityValues.CLAIM_SUBMISSION) {
      setStatusColor({ bgcolor: "success.light", color: "success.main", borderColor: "success.main" });
    }
    if (label === ClaimActivityValues.LEAD_REVIEW) {
      switch (claimStatus) {
        case ClaimStatus.PENDING_LEAD:
          setStatusLabel(StatusLabel.PENDING);
          setStatusColor({ bgcolor: "warning.light", color: "warning.main", borderColor: "warning.main" });
          break;

        case ClaimStatus.LEAD_REJECTED:
          setStatusLabel(StatusLabel.REJECTED);
          setStatusColor({ bgcolor: "error.light", color: "error.main", borderColor: "error.main" });
          break;

        case ClaimStatus.PENDING_FINANCE:
        case ClaimStatus.FINANCE_REJECTED:
        case ClaimStatus.APPROVED:
          setStatusColor({ bgcolor: "success.light", color: "success.main", borderColor: "success.main" });
          break;

        default:
          setStatusColor({ bgcolor: "cardBackground.main", color: "grey.500", borderColor: "grey.500" });
      }
    }
    if (label === ClaimActivityValues.FINANCE_REVIEW) {
      switch (claimStatus) {
        case ClaimStatus.PENDING_FINANCE:
          setStatusLabel(StatusLabel.PENDING);
          setStatusColor({ bgcolor: "warning.light", color: "warning.main", borderColor: "warning.main" });
          break;

        case ClaimStatus.FINANCE_REJECTED:
          setStatusLabel(StatusLabel.REJECTED);
          setStatusColor({ bgcolor: "error.light", color: "error.main", borderColor: "error.main" });
          break;

        case ClaimStatus.APPROVED:
          setStatusLabel(StatusLabel.APPROVED);
          setStatusColor({ bgcolor: "success.light", color: "success.main", borderColor: "success.main" });
          break;

        default:
          setStatusColor({ bgcolor: "cardBackground.main", color: "grey.500", borderColor: "grey.500" });
      }
    }
  }, []);

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          sx={{
            boxShadow: "none",
            marginLeft: 0,
            ...statusColor,
          }}
        >
          <Icon sx={{ padding: "3px", width: "23px", height: "23px" }} />
        </TimelineDot>
        {!isLastItem && <TimelineConnector sx={{ minHeight: "80px" }} />}
      </TimelineSeparator>
      <TimelineContent sx={{ paddingTop: date || rejectedReason ? "8px" : "18px" }}>
        <Stack>
          <Typography fontSize={"16px"} fontWeight={500} sx={{ color: statusColor?.color }}>
            {label}
            {statusLabel && <span style={{ fontSize: "13px" }}>{" (" + statusLabel + ")"}</span>}
          </Typography>
          <Typography fontSize={"13px"}>{date && dayjs(date).format("DD-MMM-YYYY")}</Typography>
          {rejectedReason && (
            <Stack direction={"row"} paddingTop={"8px"}>
              <Typography fontSize={"13px"} sx={{ wordBreak: "break-all" }}>
                <span style={{ fontWeight: 500 }}>Reason:</span> {rejectedReason}
              </Typography>
            </Stack>
          )}
        </Stack>
      </TimelineContent>
    </TimelineItem>
  );
};
