import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ButtonState, ClaimItem, ClaimItemPayload, DraftStatus } from "../../utils/types";
import { enqueueSnackbar } from "notistack";
import { RootState } from "..";
import { AxiosInstance } from "axios";
import { setConfirmationButtonState } from "./commonSlice";
import { convertToClaimItemPayload } from "../../utils/utils";

interface Claim {
  claimItems: ClaimItem[];
  totalAmount: number;
  draftClaimItems: ClaimItem[];
  draftStatus: DraftStatus;
}

const initialState: Claim = {
  claimItems: [],
  totalAmount: 0,
  draftClaimItems: [],
  draftStatus: DraftStatus.IDLE,
};

export const saveClaimDraft = createAsyncThunk(
  "form/saveClaimDraft",
  (payload: { apiInstance: AxiosInstance; claimItems: ClaimItemPayload[] }) => {
    return new Promise<void>((resolve, reject) => {
      payload.apiInstance
        .post("/claim-drafts", { transactions: payload.claimItems })
        .then(() => resolve())
        .catch(() => reject());
    });
  }
);

export const removeClaimItemAndDraft = createAsyncThunk(
  "newClaim/removeClaimItemAndDraft",
  (payload: { apiInstance: AxiosInstance; index: number }, { dispatch, getState }) => {
    const { claimItems } = (getState() as RootState).newClaim;

    return new Promise<void>((resolve, reject) => {
      const modifiedClaimItems: ClaimItemPayload[] = convertToClaimItemPayload(claimItems);
      modifiedClaimItems.splice(payload.index, 1);

      const draftAction = modifiedClaimItems.length > 0 ? "post" : "delete";

      dispatch(removeClaimItem(payload.index));
      draftAction === "delete" && dispatch(removeAllDraftClaimItems());

      payload
        .apiInstance({
          method: draftAction,
          url: "/claim-drafts",
          data: draftAction === "post" && { transactions: modifiedClaimItems },
        })
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }
);

export const submitClaim = createAsyncThunk(
  "newClaim/submitClaim",
  (payload: { apiInstance: AxiosInstance }, { dispatch, getState }) => {
    const { claimItems } = (getState() as RootState).newClaim;

    return new Promise<void>((resolve, reject) => {
      const modifiedClaimItems: ClaimItemPayload[] = convertToClaimItemPayload(claimItems);

      dispatch(setConfirmationButtonState(ButtonState.LOADING));

      payload.apiInstance
        .post("/claims", {
          transactions: modifiedClaimItems,
        })
        .then(() => {
          enqueueSnackbar("Claim submitted successfully", { variant: "success" });
          resolve();
        })
        .catch(() => {
          enqueueSnackbar(
            "Error submitting claim. Please try again. If the issue persists, contact Internal Apps Team",
            {
              variant: "error",
            }
          );
          reject();
        })
        .finally(() => dispatch(setConfirmationButtonState(ButtonState.ACTIVE)));
    });
  }
);

export const newClaimSlice = createSlice({
  name: "newClaim",
  initialState,
  reducers: {
    addClaimItem: (state, action: PayloadAction<ClaimItem>) => {
      state.claimItems.push(action.payload);
      state.totalAmount += action.payload.reimbursementAmount;
    },
    removeClaimItem: (state, action: PayloadAction<number>) => {
      state.totalAmount -= state.claimItems[action.payload].reimbursementAmount;
      state.claimItems.splice(action.payload, 1);
    },
    editClaimItem: (state, action: PayloadAction<{ item: ClaimItem; index: number }>) => {
      state.totalAmount -= state.claimItems[action.payload.index].reimbursementAmount;
      state.claimItems.splice(action.payload.index, 1, action.payload.item);
      state.totalAmount += action.payload.item.reimbursementAmount;
    },
    removeAllClaimItems: (state) => {
      state.claimItems = [];
      state.totalAmount = 0;
    },
    setDraftClaimItems: (state, action: PayloadAction<ClaimItem[]>) => {
      state.draftClaimItems = action.payload;
    },
    removeAllDraftClaimItems: (state) => {
      state.draftClaimItems = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitClaim.fulfilled, (state) => {
      state.claimItems = [];
      state.totalAmount = 0;
      state.draftClaimItems = [];
    });
    builder
      .addCase(saveClaimDraft.pending, (state) => {
        state.draftStatus = DraftStatus.SAVING;
      })
      .addCase(saveClaimDraft.fulfilled, (state) => {
        state.draftStatus = DraftStatus.SAVED;
      })
      .addCase(saveClaimDraft.rejected, (state) => {
        state.draftStatus = DraftStatus.FAILED;
      });
    builder
      .addCase(removeClaimItemAndDraft.pending, (state) => {
        state.draftStatus = DraftStatus.SAVING;
      })
      .addCase(removeClaimItemAndDraft.fulfilled, (state) => {
        state.draftStatus = DraftStatus.SAVED;
      })
      .addCase(removeClaimItemAndDraft.rejected, (state) => {
        state.draftStatus = DraftStatus.FAILED;
      });
  },
});

export const {
  addClaimItem,
  editClaimItem,
  removeClaimItem,
  removeAllClaimItems,
  setDraftClaimItems,
  removeAllDraftClaimItems,
} = newClaimSlice.actions;

export default newClaimSlice.reducer;
