import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Typography variant="h1" style={{ color: "gray" }}>
        404
      </Typography>
      <Typography variant="h6" style={{ color: "gray" }}>
        The page you're looking for doesn't exist.
      </Typography>
      <Box height={"5px"} />
      <Button onClick={() => navigate("/")} variant="contained">
        Back Home
      </Button>
    </Box>
  );
};
