import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  SpeedDial,
  SpeedDialAction,
  Stack,
  Typography,
} from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ResetIcon from "@mui/icons-material/CenterFocusStrong";
import CloseIcon from "@mui/icons-material/Close";
import MoreIcon from "@mui/icons-material/MoreVert";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { ReceiptTypes } from "../../utils/types";
import { Document, Page, pdfjs } from "react-pdf";
import { Fragment, useEffect, useState } from "react";
import { theme } from "../../theme";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

interface ReceiptViewerProps {
  isImageViewerOpen: boolean;
  handleImageViewerClose: () => void;
  receiptUrl: string;
  isLoading: boolean;
  receiptType: ReceiptTypes | null;
}

export const ReceiptViewer: React.FC<ReceiptViewerProps> = ({
  isImageViewerOpen,
  handleImageViewerClose,
  receiptUrl,
  isLoading,
  receiptType,
}) => {
  const [numPages, setNumPages] = useState<number>();
  const [scale, setScale] = useState<number>(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (receiptType === ReceiptTypes.PDF && event.ctrlKey) {
        event.preventDefault();
        if (event.deltaY > 0) {
          setScale((prevScale) => (prevScale > 1 ? prevScale - 0.2 : prevScale));
        } else {
          setScale((prevScale) => prevScale + 0.2);
        }
      }
    };

    window.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return (
    <Dialog
      open={isImageViewerOpen}
      onClose={handleImageViewerClose}
      PaperProps={{
        style: {
          backgroundImage: "none",
          borderRadius: 6,
          maxWidth: "100%",
          width: receiptType === ReceiptTypes.PDF ? "60vw" : undefined,
          height: receiptType === ReceiptTypes.PDF ? "100%" : undefined,
        },
      }}
    >
      {isLoading ? (
        <DialogContent sx={{ overflow: "hidden" }}>
          <CircularProgress sx={{ padding: "100px" }} />
        </DialogContent>
      ) : (
        <>
          <DialogTitle
            sx={{ paddingLeft: "25px", paddingRight: "5px", paddingY: "5px" }}
            bgcolor={"secondary.main"}
            borderBottom={`2px solid ${theme.palette.border.dark}`}
          >
            <Stack justifyContent={"space-between"} direction={"row"} alignItems={"center"}>
              <Typography color={"white"} fontWeight={500} fontSize={"14px"}>
                {"Claim Item Receipt"}
              </Typography>
              <IconButton onClick={handleImageViewerClose}>
                <CloseIcon
                  sx={{
                    color: "whitesmoke",
                    width: "17px",
                    height: "17px",
                  }}
                />
              </IconButton>
            </Stack>
          </DialogTitle>
          {receiptType === ReceiptTypes.IMAGE ? (
            <TransformWrapper initialScale={1} initialPositionX={0} initialPositionY={0}>
              {({ zoomIn, zoomOut, resetTransform }) => (
                <Stack>
                  <DialogContent sx={{ padding: 0 }}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <TransformComponent>
                        <img
                          src={receiptUrl}
                          style={{
                            maxHeight: "75vh",
                            width: "100%",
                          }}
                        />
                      </TransformComponent>
                    </Box>
                  </DialogContent>
                  <SpeedDial
                    ariaLabel="Zoom controls"
                    direction="left"
                    sx={{
                      position: "absolute",
                      bottom: 16,
                      right: 16,
                      margin: 0,
                      "& .MuiFab-primary": {
                        fontSize: "10px",
                        width: "40px",
                        height: "40px",
                        bgcolor: "secondary.main",
                      },
                    }}
                    icon={<MoreIcon />}
                  >
                    <SpeedDialAction icon={<ZoomInIcon />} tooltipTitle={"Zoom in"} onClick={() => zoomIn()} />
                    <SpeedDialAction icon={<ZoomOutIcon />} tooltipTitle={"Zoom out"} onClick={() => zoomOut()} />
                    <SpeedDialAction icon={<ResetIcon />} tooltipTitle={"Reset"} onClick={() => resetTransform()} />
                  </SpeedDial>
                </Stack>
              )}
            </TransformWrapper>
          ) : (
            <DialogContent sx={{ padding: 0, display: "flex", justifyContent: "center", bgcolor: "#333333" }}>
              <Document file={receiptUrl} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.from(new Array(numPages), (_el, index) => (
                  <Fragment key={index}>
                    <Page pageNumber={index + 1} scale={scale} />
                    <Box height={"30px"}></Box>
                  </Fragment>
                ))}
              </Document>
              <SpeedDial
                ariaLabel="Zoom Controls"
                direction="left"
                sx={{
                  position: "absolute",
                  bottom: 16,
                  right: 16,
                  margin: 0,
                  "& .MuiFab-primary": {
                    fontSize: "10px",
                    width: "40px",
                    height: "40px",
                    bgcolor: "secondary.main",
                  },
                }}
                icon={<MoreIcon />}
              >
                <SpeedDialAction
                  icon={<ZoomInIcon />}
                  tooltipTitle={"Zoom in"}
                  onClick={() => setScale((prevScale) => prevScale + 0.2)}
                />
                <SpeedDialAction
                  icon={<ZoomOutIcon />}
                  tooltipTitle={"Zoom out"}
                  onClick={() => setScale((prevScale) => prevScale - 0.2)}
                />
                <SpeedDialAction icon={<ResetIcon />} tooltipTitle={"Reset"} onClick={() => setScale(1)} />
              </SpeedDial>
            </DialogContent>
          )}
        </>
      )}
    </Dialog>
  );
};
