import { ClaimItem, ClaimItemPayload, ClaimStatus, Employee } from "./types";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const changeStatusLabel = (status: string) => {
  switch (status) {
    case ClaimStatus.PENDING_LEAD:
      return "Pending Lead";
    case ClaimStatus.LEAD_REJECTED:
      return "Lead Rejected";
    case ClaimStatus.PENDING_FINANCE:
      return "Pending Finance";
    case ClaimStatus.APPROVED:
      return "Approved";
    default:
      return "Finance Rejected";
  }
};

export const getEmployeeDetails = (email: string, employees: Employee[]) =>
  employees.find((employee) => employee.workEmail === email) ?? null;

export const areArraysEqual = (array1: ClaimItem[], array2: ClaimItem[]) => {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    const object1 = array1[i];
    const object2 = array2[i];

    let key: keyof ClaimItem;
    for (key in object1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
  }

  return true;
};

export const convertUTCtoLocal = (utcDate: string) => {
  return dayjs.utc(utcDate).local().format("DD-MMM-YYYY");
};

export const getEmployeeName = (employeeDetails: Employee) => {
  const { firstName, lastName } = employeeDetails;
  return firstName && lastName ? `${firstName} ${lastName}` : null;
};

export const getStatusColor = (status: ClaimStatus, isHover?: boolean) => {
  switch (status) {
    case ClaimStatus.APPROVED:
      return { bgcolor: isHover ? "success.300" : "success.light", color: "success.main" };
    case ClaimStatus.PENDING_LEAD:
    case ClaimStatus.PENDING_FINANCE:
      return { bgcolor: isHover ? "warning.300" : "warning.light", color: "warning.main" };
    default:
      return { bgcolor: isHover ? "error.300" : "error.light", color: "error.main" };
  }
};

export const convertToClaimItemPayload = (claimItems: ClaimItem[]): ClaimItemPayload[] => {
  return claimItems.map((claimItem) => ({
    date: claimItem.date,
    travelJobNumber: claimItem.travelJobNumber,
    currency: claimItem.currency,
    amount: claimItem.amount,
    expenseTypeId: claimItem.expenseTypeId,
    comment: claimItem.comment,
    receiptUrl: claimItem.receiptUrl,
  }));
};
