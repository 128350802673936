import {
  Box,
  Button,
  Drawer,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Claim, ClaimActivityValues, ClaimReviewAction, ClaimStatus, View } from "../../utils/types";
import React, { useEffect, useState } from "react";
import { Circle } from "@mui/icons-material";
import ClaimActivityImage from "../../assets/images/claim-activity.svg";
import { Timeline, timelineItemClasses } from "@mui/lab";
import ClaimSubmissionIcon from "@mui/icons-material/ListAlt";
import LeadIcon from "@mui/icons-material/Person4Outlined";
import FinanceIcon from "@mui/icons-material/Groups3Outlined";
import CloseIcon from "@mui/icons-material/Close";
import ForwardArrowIcon from "@mui/icons-material/ArrowForwardIos";
import { CustomTimelineItem } from "../claim/CustomTimelineItem";
import NoDataImage from "../../assets/images/no-data.svg";
import NoPendingClaimsImage from "../../assets/images/no-pending-claims.svg";
import { NumericFormat } from "react-number-format";
import { changeStatusLabel, convertUTCtoLocal, getStatusColor } from "../../utils/utils";
import { useAppDispatch, useAppSelector } from "../../store";
import { SkeletonTable } from "./SkeletonTable";
import { setClaims, setReviewedClaimDetails } from "../../store/slices/tableSlice";
import { theme } from "../../theme";
import { UserCard } from "../claim/UserCard";
import { setSelectedClaim } from "../../store/slices/claimDetailsSlice";

interface ClaimTableProps {
  view: View;
  isPendingClaims?: boolean;
  isLoading: boolean;
}

export const ClaimTable: React.FC<ClaimTableProps> = ({ view, isPendingClaims, isLoading }) => {
  const claims = useAppSelector((state) => state.table.claims);
  const reviewedClaimDetails = useAppSelector((state) => state.table.reviewedClaimDetails);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedClaimForActivity, setSelectedClaimForActivity] = useState<Claim | null>(null);
  const [highlightedClaimItemId, setHighlightedClaimItemId] = useState<string | null>(null);
  const [amount, setAmount] = useState(100);

  const dispatch = useAppDispatch();

  const headerCellStyle = {
    paddingY: "5px",
    textWrap: "nowrap",
    fontSize: "16px",
    fontWeight: 600,
  };

  const getButtonLabel = (claim: Claim) => {
    if (view === View.LEAD || view === View.FINANCE) {
      return isPendingClaims ? "Review" : "View";
    } else {
      if (
        claim.statusDetails.status === ClaimStatus.LEAD_REJECTED ||
        claim.statusDetails.status === ClaimStatus.FINANCE_REJECTED
      ) {
        return "View / Resubmit";
      } else {
        return "View";
      }
    }
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    if (reviewedClaimDetails) {
      for (let index = 100; index > 0; index--) {
        setTimeout(() => {
          setAmount(index);
        }, 17 * (100 - index));
      }

      setTimeout(() => {
        const newClaims = claims.filter((claim) => claim.id !== reviewedClaimDetails.claim.id);
        dispatch(setClaims(newClaims));
        setAmount(100);
        dispatch(setReviewedClaimDetails(null));
      }, 1700);
    }
  }, [reviewedClaimDetails]);

  return (
    <>
      <Table stickyHeader sx={{ height: claims.length === 0 ? "100%" : undefined }}>
        <TableHead>
          <TableRow>
            <TableCell sx={headerCellStyle}>Claim ID</TableCell>
            {(view === View.LEAD || view === View.FINANCE) && <TableCell sx={headerCellStyle}>User</TableCell>}
            <TableCell sx={headerCellStyle}>Submitted Date</TableCell>
            <TableCell sx={headerCellStyle}>Total Amount</TableCell>
            {view === View.USER && <TableCell sx={headerCellStyle}>Status</TableCell>}
            <TableCell sx={{ ...headerCellStyle, textAlign: "right" }}>Actions</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {isLoading ? (
            <SkeletonTable />
          ) : claims.length > 0 ? (
            <>
              {claims.map((claim: Claim, index) => (
                <TableRow
                  hover
                  sx={{
                    backgroundColor:
                      highlightedClaimItemId === claim.id
                        ? reviewedClaimDetails
                          ? reviewedClaimDetails.status === ClaimReviewAction.APPROVED
                            ? "success.light"
                            : "error.light"
                          : "primary.light"
                        : "white",
                    opacity: reviewedClaimDetails && highlightedClaimItemId === claim.id ? `${amount}%` : "100%",
                    "&:hover": {
                      backgroundColor:
                        highlightedClaimItemId === claim.id
                          ? reviewedClaimDetails
                            ? reviewedClaimDetails.status === ClaimReviewAction.APPROVED
                              ? theme.palette.success.light + " !important"
                              : theme.palette.error.light + " !important"
                            : theme.palette.primary.light + " !important"
                          : "white",
                    },
                  }}
                  key={claim.id}
                >
                  <TableCell sx={{ textWrap: "nowrap" }}>
                    <Typography fontSize={"14px"} title={claim.id} sx={{ display: "inline" }}>
                      {claim.id}
                    </Typography>
                  </TableCell>
                  {(view === "lead" || view === "finance") && (
                    <TableCell size="small">
                      <UserCard email={claim.employeeEmail} />
                    </TableCell>
                  )}
                  <TableCell sx={{ textWrap: "nowrap" }}>
                    <Typography
                      fontSize={"14px"}
                      title={convertUTCtoLocal(claim.createdDate)}
                      sx={{ display: "inline" }}
                    >
                      {convertUTCtoLocal(claim.createdDate)}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ textWrap: "nowrap" }}>
                    <Box title={claim.currencyCode + " " + claim.totalAmount} sx={{ display: "inline" }}>
                      <NumericFormat
                        value={claim.totalAmount}
                        decimalScale={2}
                        fixedDecimalScale
                        thousandSeparator
                        displayType="text"
                      />
                      {" " + claim.currencyCode}
                    </Box>
                  </TableCell>
                  {view === "user" && (
                    <TableCell sx={{ paddingY: "3px", textWrap: "nowrap" }}>
                      <Button
                        variant="contained"
                        size="small"
                        disableElevation
                        sx={{
                          ...getStatusColor(claim.statusDetails.status),
                          textTransform: "none",
                          borderRadius: 1,
                          border: `1px solid ${theme.palette.border.main}`,
                          "&:hover": {
                            bgcolor: getStatusColor(claim.statusDetails.status, true).bgcolor,
                          },
                        }}
                        onClick={() => {
                          setSelectedClaimForActivity(claims[index]);
                          setDrawerOpen(true);
                        }}
                      >
                        <Stack direction={"row"} alignItems="center" spacing={1}>
                          <Circle sx={{ width: "10px", height: "10px" }} />
                          <Typography fontSize={"13px"}>{changeStatusLabel(claim.statusDetails.status)}</Typography>
                          <ForwardArrowIcon sx={{ fontSize: 13 }} />
                        </Stack>
                      </Button>
                    </TableCell>
                  )}
                  <TableCell align="right" padding={"none"} sx={{ paddingRight: "5px" }}>
                    <Button
                      variant="contained"
                      sx={{
                        bgcolor:
                          view === "user" &&
                          (claim.statusDetails.status === ClaimStatus.LEAD_REJECTED ||
                            claim.statusDetails.status === ClaimStatus.FINANCE_REJECTED)
                            ? "warning.main"
                            : "grey.500",
                        fontSize: "12px",
                        textWrap: "nowrap",
                        "&:hover": {
                          bgcolor:
                            view === "user" &&
                            (claim.statusDetails.status === ClaimStatus.LEAD_REJECTED ||
                              claim.statusDetails.status === ClaimStatus.FINANCE_REJECTED)
                              ? "warning.dark"
                              : "grey.900",
                        },
                        width: "130px",
                      }}
                      size="small"
                      onClick={() => {
                        setHighlightedClaimItemId(claim.id);
                        dispatch(setSelectedClaim(claim));
                      }}
                    >
                      {getButtonLabel(claim)}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </>
          ) : (
            <TableRow>
              <TableCell colSpan={5} align="center">
                <img
                  src={
                    (view === View.LEAD || view === View.FINANCE) && isPendingClaims
                      ? NoPendingClaimsImage
                      : NoDataImage
                  }
                  height="180px"
                />
                <Box height={"25px"} />
                <Typography fontWeight={500} fontSize="16px">
                  {(view === View.LEAD || view === View.FINANCE) && isPendingClaims
                    ? "All caught up! No claims to approve"
                    : "No claims found"}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <Drawer
        open={drawerOpen}
        anchor="right"
        onClose={handleDrawerClose}
        sx={{
          "& .MuiDrawer-paper": {
            width: "350px",
            boxSizing: "border-box",
            bgcolor: "white",
            paddingLeft: "30px",
            paddingTop: "20px",
          },
        }}
      >
        <Stack direction={"row"} justifyContent={"space-between"} paddingRight={"15px"} alignItems={"center"}>
          <Typography fontSize={"20px"} fontWeight={600}>
            Claim Activity
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <img src={ClaimActivityImage} width={"220px"} style={{ marginTop: "45px", marginBottom: "30px" }} />
        {selectedClaimForActivity && (
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            <CustomTimelineItem
              label={ClaimActivityValues.CLAIM_SUBMISSION}
              Icon={ClaimSubmissionIcon}
              selectedClaim={selectedClaimForActivity}
            />
            <CustomTimelineItem
              label={ClaimActivityValues.LEAD_REVIEW}
              Icon={LeadIcon}
              selectedClaim={selectedClaimForActivity}
            />
            <CustomTimelineItem
              label={ClaimActivityValues.FINANCE_REVIEW}
              Icon={FinanceIcon}
              selectedClaim={selectedClaimForActivity}
              isLastItem={true}
            />
          </Timeline>
        )}
      </Drawer>
    </>
  );
};
