export const appDescription = "Manage your expense claims effortlessly. Submit, track, and approve claims with ease";

export const sideBarWidth = 240;

export const commentMaxCharacters = 100;

//This is the maximum file size in MB
export const maxFileSize = 5;

export const currencyRegex = /^[0-9]+(\.[0-9]+)?$/;
