import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ExchangeRate, ExpenseType } from "../../utils/types";
import { enqueueSnackbar } from "notistack";
import { RootState } from "..";
import { AxiosInstance } from "axios";

interface Form {
  selectedBillDate: string;
  selectedJobNumber: string | null;
  exchangeRates: ExchangeRate[];
  expenseTypes: ExpenseType[];
  isRatesFetching: boolean;
  isExpenseTypesFetching: boolean;
  isReceiptAttached: boolean;
  temporaryReceiptUrl: string;
  receiptUploadProgress: number;
}

const initialState: Form = {
  selectedBillDate: "",
  selectedJobNumber: "",
  exchangeRates: [],
  expenseTypes: [],
  isRatesFetching: false,
  isExpenseTypesFetching: false,
  isReceiptAttached: false,
  temporaryReceiptUrl: "",
  receiptUploadProgress: 0,
};

export const fetchExchangeRates = createAsyncThunk(
  "form/fetchExchangeRates",
  (payload: { apiInstance: AxiosInstance, reimbursementCurrency: string }, { getState }) => {
    const { selectedBillDate } = (getState() as RootState).form;

    return new Promise<ExchangeRate[]>((resolve, reject) => {
      payload.apiInstance
        .get("/currencies/" + payload.reimbursementCurrency + "/rates/" + selectedBillDate)
        .then((res) => {
          resolve(res.data as ExchangeRate[]);
        })
        .catch(() => {
          enqueueSnackbar(
            "Failed to fetch exchange rates. Please try again. If the issue persists, contact Internal Apps Team",
            {
              variant: "error",
            }
          );
          reject();
        });
    });
  }
);

export const fetchExpenseTypes = createAsyncThunk(
  "form/fetchExpenseTypes",
  (payload: { apiInstance: AxiosInstance; selectedJobNumber?: string }) => {
    return new Promise<ExpenseType[]>((resolve, reject) => {
      payload.apiInstance
        .get("/user-configurations/expense-types", { params: { travelJobNumber: payload.selectedJobNumber } })
        .then((res) => {
          resolve(res.data as ExpenseType[]);
        })
        .catch(() => {
          enqueueSnackbar(
            "Failed to fetch expense types. Please try again. If the issue persists, contact Internal Apps Team",
            {
              variant: "error",
            }
          );
          reject();
        });
    });
  }
);

export const uploadReceipt = createAsyncThunk(
  "form/uploadReceipt",
  (payload: { apiInstance: AxiosInstance; file: File; userEmail: string }, { dispatch }) => {
    return new Promise<string>((resolve, reject) => {
      payload.apiInstance
        .post("/claims/" + payload.userEmail + "/transactions/receipts/file", new Blob([payload.file]), {
          headers: { "Content-Type": payload.file.type },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total!);
            dispatch(setReceiptUploadProgress(percent));
          },
        })
        .then((res) => {
          resolve(res.data.fileName as string);
        })
        .catch(() => {
          enqueueSnackbar(
            "Failed to upload receipt. Please try again. If the issue persists, contact Internal Apps Team",
            {
              variant: "error",
            }
          );
          reject();
        });
    });
  }
);

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setSelectedBillDate: (state, action: PayloadAction<string>) => {
      state.selectedBillDate = action.payload;
    },
    setSelectedJobNumber: (state, action: PayloadAction<string | null>) => {
      state.selectedJobNumber = action.payload;
    },
    setExchangeRates: (state, action: PayloadAction<ExchangeRate[]>) => {
      state.exchangeRates = action.payload;
    },
    setExpenseTypes: (state, action: PayloadAction<ExpenseType[]>) => {
      state.expenseTypes = action.payload;
    },
    setIsRatesFetching: (state, action: PayloadAction<boolean>) => {
      state.isRatesFetching = action.payload;
    },
    setIsExpenseTypesFetching: (state, action: PayloadAction<boolean>) => {
      state.isExpenseTypesFetching = action.payload;
    },
    setIsReceiptAttached: (state, action: PayloadAction<boolean>) => {
      state.isReceiptAttached = action.payload;
    },
    setTemporaryReceiptUrl: (state, action: PayloadAction<string>) => {
      state.temporaryReceiptUrl = action.payload;
    },
    setReceiptUploadProgress: (state, action: PayloadAction<number>) => {
      state.receiptUploadProgress = action.payload;
    },
    resetForm: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExchangeRates.pending, (state) => {
        state.isRatesFetching = true;
      })
      .addCase(fetchExchangeRates.fulfilled, (state, action) => {
        state.exchangeRates = action.payload;
        state.isRatesFetching = false;
      })
      .addCase(fetchExchangeRates.rejected, (state) => {
        state.isRatesFetching = false;
      });

    builder
      .addCase(fetchExpenseTypes.pending, (state) => {
        state.isExpenseTypesFetching = true;
      })
      .addCase(fetchExpenseTypes.fulfilled, (state, action) => {
        state.expenseTypes = action.payload;
        state.isExpenseTypesFetching = false;
      })
      .addCase(fetchExpenseTypes.rejected, (state) => {
        state.isExpenseTypesFetching = false;
      });

    builder.addCase(uploadReceipt.rejected, (state) => {
      state.receiptUploadProgress = 0;
    });
  },
});

export const {
  setSelectedBillDate,
  setSelectedJobNumber,
  setExchangeRates,
  setExpenseTypes,
  setIsRatesFetching,
  setIsExpenseTypesFetching,
  setIsReceiptAttached,
  setTemporaryReceiptUrl,
  setReceiptUploadProgress,
  resetForm,
} = formSlice.actions;

export default formSlice.reducer;
