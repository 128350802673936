import { Avatar, Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import { SettingsMenu } from "./SettingsMenu";
import { useEffect, useState } from "react";
import logo from "../../assets/images/wso2-logo-white.png";
import { theme } from "../../theme";
import { useAppSelector } from "../../store";
import { getEmployeeDetails, getEmployeeName } from "../../utils/utils";
import { appName } from "../../config";

export const TopStickyBar = () => {
  const userEmail = useAppSelector((state) => state.appData.userEmail);
  const userImage = useAppSelector((state) => state.appData.userImage);
  const employees = useAppSelector((state) => state.appData.employees);
  const leadName = useAppSelector((state) => state.appData.leadName);
  const leadEmail = useAppSelector((state) => state.appData.leadEmail);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [employeeName, setEmployeeName] = useState<string | null>(null);

  useEffect(() => {
    const employeeDetails = getEmployeeDetails(userEmail, employees);
    employeeDetails && setEmployeeName(getEmployeeName(employeeDetails));
  }, []);

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      paddingRight={"20px"}
      paddingY={"5px"}
      zIndex={2}
      borderBottom={"1px solid"}
      bgcolor={theme.palette.secondary.main}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
        component="img"
        alt="The house from the offer."
        src={logo}
        height={25}
        paddingLeft={"20px"}
      />
      <Divider
        orientation="vertical"
        sx={{
          borderRightWidth: 2,
          marginLeft: "20px",
          marginRight: "20px",
          fontSize: 16,
          height: 20,
          bgcolor: "white",
        }}
      />
      <Typography fontSize={16} fontWeight={600} color={"white"} width={"100%"} variant="h1">
        {appName}
      </Typography>
      <Stack alignItems={"end"}>
        <Typography color={"white"} paddingRight={"10px"} fontSize={14} fontWeight={500} noWrap>
          {employeeName || userEmail}
        </Typography>
        <Box height={"2px"} />
        {(leadName || (leadEmail && leadEmail.length > 0)) && (
          <Typography color={"white"} paddingRight={"10px"} fontSize={12} noWrap>
            {"Lead: " + (leadName || leadEmail)}
          </Typography>
        )}
      </Stack>
      <IconButton onClick={(event) => setAnchorEl(event.currentTarget)} size="small">
        <Avatar src={userImage || undefined} sx={{ width: 40, height: 40 }} alt={employeeName || userEmail} />
      </IconButton>
      <SettingsMenu handleMenuClose={() => setAnchorEl(null)} anchorEl={anchorEl} />
    </Stack>
  );
};
