import { Box, Card, Stack, Typography, alpha } from "@mui/material";
import { theme } from "../../theme";
import { useEffect, useState } from "react";
import { NumericFormat, numericFormatter } from "react-number-format";
import { currencyRegex } from "../../utils/constants";

interface AmountPreviewCardProps {
  conversionRate: number;
  amount: number;
  transactionCurrency: string;
  reimbursementCurrency: string;
  isDetailedCard?: boolean;
  setFieldValue?: (field: string, value: any) => void;
}

export const AmountPreviewCard: React.FC<AmountPreviewCardProps> = ({
  conversionRate,
  amount,
  transactionCurrency,
  reimbursementCurrency,
  isDetailedCard,
  setFieldValue,
}) => {
  const [reimbursementAmount, setReimbursementAmount] = useState<number>(0);

  useEffect(() => {
    if (amount && conversionRate != 0 && currencyRegex.test(amount.toString())) {
      const calcReimbursementAmount = conversionRate * amount;
      setReimbursementAmount(calcReimbursementAmount);
      setFieldValue &&
        setFieldValue(
          "reimbursementAmount",
          Number(numericFormatter(calcReimbursementAmount.toString(), { decimalScale: 2 }))
        );
    } else {
      setReimbursementAmount(0);
      setFieldValue && setFieldValue("reimbursementAmount", 0);
    }
  }, [amount, conversionRate]);

  return (
    <Card
      variant="outlined"
      sx={{
        py: 1,
        px: 1.5,
        borderColor: alpha(theme.palette.primary.main, 0.5),
        bgcolor: alpha(theme.palette.primary.light, 0.5),
      }}
    >
      <Stack direction={"row"} justifyContent={"space-between"} spacing={5}>
        <Typography fontSize={14} fontWeight={isDetailedCard ? "bold" : 400} lineHeight={1.3}>
          Reimbursement
          <br />
          Amount
        </Typography>
        <Stack sx={{ textAlign: "right" }}>
          <NumericFormat
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
            displayType="text"
            value={reimbursementAmount}
            renderText={(value) => (
              <Typography
                fontSize={16}
                fontWeight={isDetailedCard ? "bold" : 500}
                title={reimbursementAmount !== 0 ? value + " " + reimbursementCurrency : "N/A"}
              >
                {reimbursementAmount !== 0 ? value + " " + reimbursementCurrency : "N/A"}
              </Typography>
            )}
          />
          {!isDetailedCard && (
            <NumericFormat
              thousandSeparator
              fixedDecimalScale
              displayType="text"
              value={conversionRate}
              renderText={(value) => (
                <Typography fontSize={12} fontWeight={500}>
                  {conversionRate > 0 ? `(1 ${transactionCurrency} = ${value} ${reimbursementCurrency})` : "N/A"}
                </Typography>
              )}
            />
          )}
        </Stack>
      </Stack>
      {isDetailedCard && (
        <>
          <Box height={"6px"} />
          <Stack direction={"row"} justifyContent={"space-between"} spacing={12}>
            <Typography fontSize={12}> Conversion Rate</Typography>
            <NumericFormat
              thousandSeparator
              fixedDecimalScale
              displayType="text"
              value={conversionRate}
              renderText={(value) => (
                <Typography fontSize={12} fontWeight={500}>
                  {conversionRate > 0 ? `(1 ${transactionCurrency} = ${value} ${reimbursementCurrency})` : "N/A"}
                </Typography>
              )}
            />
          </Stack>
          <Stack direction={"row"} justifyContent={"space-between"} width={"100%"} alignItems={"center"} spacing={12}>
            <Typography fontSize={12}> Transaction Amount</Typography>
            <NumericFormat
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
              displayType="text"
              value={amount}
              renderText={(value) => (
                <Typography
                  fontSize={12}
                  fontWeight={500}
                  title={(value != "0" ? value : "N/A") + ` ${transactionCurrency}`}
                >
                  {(value != "0" ? value : "N/A") + ` ${transactionCurrency}`}
                </Typography>
              )}
            />
          </Stack>
        </>
      )}
    </Card>
  );
};
