import { TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { NumberFormatValues, NumericFormat } from "react-number-format";

interface CustomCurrencyFieldProps {
  id: string;
  label: string;
  disabled?: boolean;
}

export const CustomCurrencyField: React.FC<CustomCurrencyFieldProps> = ({ id, label, disabled }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(id);

  const handleChange = (values: NumberFormatValues) => {
    setFieldValue(id, values.floatValue);
  };

  return (
    <NumericFormat
      {...field}
      customInput={TextField}
      disabled={disabled}
      value={field.value === 0 ? "" : field.value}
      autoComplete="off"
      sx={{
        flex: 3.5,
        ...(disabled && {
          ".MuiOutlinedInput-root": {
            bgcolor: "disable.main",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "disable.dark",
            },
          },
        }),
      }}
      size="small"
      label={label}
      required
      onValueChange={handleChange}
      onChange={undefined}
      error={meta && meta.touched && meta.error && !disabled ? true : false}
      helperText={meta && meta.touched && meta.error && !disabled ? meta.error : " "}
      thousandSeparator={true}
      decimalScale={2}
      isAllowed={(values) => {
        const { floatValue } = values;
        return floatValue ? floatValue > 0 && floatValue < 1000000000001 : true;
      }}
    />
  );
};
