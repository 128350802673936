import { Box, Card, CardContent, Container, Divider, Grid, Stack, Typography } from "@mui/material";
import BackgroundImage from "../assets/images/expense-claims-app-login-background.png";
import ProductLogos from "../assets/images/expense-claims-app-login-logos.png";
import logo from "../assets/images/wso2-logo-black.png";
import { useAuthContext } from "@asgardeo/auth-react";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import { format } from "date-fns";
import { appName } from "../config";
import { appDescription } from "../utils/constants";
import { PageLoader } from "../components/PageLoader";

export const Login = () => {
  const { signIn, state } = useAuthContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isSessionActive = localStorage.getItem("asgardeo-session-active") === "true";
  sessionStorage.setItem("redirect-path", window.location.pathname);

  return isSessionActive ? (
    <PageLoader />
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Container fixed maxWidth="xs">
        <Card
          elevation={24}
          sx={{
            borderRadius: 3,
            pt: 3,
            mx: 1,
            backgroundColor: "white",
          }}
        >
          <CardContent>
            <Box
              sx={{
                px: 1,
                backgroundColor: "white",
              }}
            >
              <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2} p={1}>
                <Grid item xs={12}>
                  <img alt="logo" width="130" height="auto" src={logo}></img>
                </Grid>
                <Grid item xs={12}>
                  <Typography align="center" sx={{ fontWeight: "bold" }} variant="h5" color={"black"}>
                    {appName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ pb: 2 }}>
                  <Typography align="center" sx={{ fontSize: "1em" }} color={"black"} fontWeight={"400"}>
                    {appDescription}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <LoadingButton
                    loading={isLoading || state.isLoading}
                    variant="contained"
                    color="primary"
                    sx={{ fontWeight: "bold" }}
                    onClick={() => {
                      signIn();
                      setIsLoading(true);
                    }}
                  >
                    LOG IN
                  </LoadingButton>
                </Grid>
                <Grid item xs={12} mt={6}>
                  <Stack direction="column" spacing={2}>
                    <Typography align="center" color={"black"}>
                      Powered By
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      <img height={22} src={ProductLogos} />
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={12} mt={3}>
                  <Typography align="center" color={"grey"} sx={{ fontSize: "0.8em" }}>
                    {`© ${format(new Date(), "yyyy")} WSO2 LLC`}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
          <Divider />
        </Card>
      </Container>
    </Box>
  );
};
