import { Slide } from "@mui/material";
import { TransitionProps } from "notistack";
import { forwardRef } from "react";

export const PopupTransition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
