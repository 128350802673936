import { Stack, Typography } from "@mui/material";
import React from "react";
import { View } from "../../utils/types";
import { FilterHolder } from "../filter/FilterHolder";

interface CustomCardHeaderProps {
  title: string;
  view: View;
}

export const CustomCardHeader: React.FC<CustomCardHeaderProps> = ({ title, view }) => {
  return (
    <Stack direction={"row"} justifyContent={"space-between"} position={"relative"}>
      <Typography fontSize={20} fontWeight={600}>
        {title}
      </Typography>
      <FilterHolder view={view} />
    </Stack>
  );
};
