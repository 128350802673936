import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { ReactNode } from "react";
import { PopupTransition } from "./PopupTransition";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch, useAppSelector } from "../store";
import { setClaimRejectionReason } from "../store/slices/claimDetailsSlice";
import { commentMaxCharacters } from "../utils/constants";

interface ConfirmationDialogProps {
  isOpen: boolean;
  isLoading?: boolean;
  isReject?: boolean;
  dialogTitle: string;
  dialogContent: string | ReactNode;
  confirmButtonLabel: string;
  confirmButtonColor?: "primary" | "secondary" | "error" | "success" | "info" | "warning" | "inherit";
  handleClose: () => void;
  handleConfirm: () => void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  isOpen,
  isLoading,
  isReject,
  dialogTitle,
  dialogContent,
  confirmButtonLabel,
  confirmButtonColor,
  handleClose,
  handleConfirm,
}) => {
  const rejectionReasonLength = useAppSelector((state) => state.claimDetails.claimRejectionReason)?.length || 0;

  const dispatch = useAppDispatch();

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={PopupTransition}
      PaperProps={{
        style: {
          minWidth: "600px",
        },
      }}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <Stack spacing={1.2}>
          {typeof dialogContent === "string" ? (
            <Typography fontSize={"15px"} lineHeight={1.6}>
              {dialogContent}
            </Typography>
          ) : (
            dialogContent
          )}
          {isReject && (
            <TextField
              autoComplete="off"
              placeholder="Provide a Reason for Rejecting the Expense Claim"
              disabled={isLoading}
              multiline
              minRows={3}
              fullWidth
              inputProps={{ maxLength: commentMaxCharacters }}
              onChange={(event) => dispatch(setClaimRejectionReason(event.target.value))}
              helperText={
                <Typography fontSize={10} component={"span"} style={{ textAlign: "right", display: "block" }}>
                  {rejectionReasonLength}/{commentMaxCharacters}
                </Typography>
              }
            ></TextField>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
          }}
          disabled={isLoading}
          sx={{ color: "text.secondary" }}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={() => {
            handleConfirm();
          }}
          disabled={isReject && rejectionReasonLength === 0}
          color={confirmButtonColor}
        >
          {confirmButtonLabel}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
