import { Box, LinearProgress, Stack, Typography, Zoom } from "@mui/material";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import Dropzone, { FileRejection } from "react-dropzone";
import FileUploadIcon from "@mui/icons-material/FileUploadOutlined";
import FileIcon from "@mui/icons-material/DescriptionOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSnackbar } from "notistack";
import imageCompression from "browser-image-compression";
import { useApiService } from "../../utils/apiService";
import { ClaimItem, ReceiptDetails } from "../../utils/types";
import { RootState, useAppDispatch, useAppSelector } from "../../store";
import {
  setIsReceiptAttached,
  setReceiptUploadProgress,
  setTemporaryReceiptUrl,
  uploadReceipt,
} from "../../store/slices/formSlice";
import { getReceiptUrl } from "../../store/slices/claimDetailsSlice";
import { maxFileSize } from "../../utils/constants";
import { theme } from "../../theme";

interface FileUploadAreaProps {
  isEdit?: boolean;
  disabled?: boolean;
}

export const FileUploadArea: React.FC<FileUploadAreaProps> = ({ isEdit, disabled }) => {
  const temporaryReceiptUrl = useAppSelector((state) => state.form.temporaryReceiptUrl);
  const userEmail = useAppSelector((state: RootState) => state.appData.userEmail);
  const receiptUploadProgress = useAppSelector((state: RootState) => state.form.receiptUploadProgress);

  const [dots, setDots] = useState(0);
  const [uploadStatus, setUploadStatus] = useState<"idle" | "processing" | "completed" | "failed">("idle");
  const [fileName, setFileName] = useState<string>("");
  const [fileURL, setFileURL] = useState<string>("");

  const { setFieldValue } = useFormikContext();
  const { values }: { values: ClaimItem } = useFormikContext();
  const { enqueueSnackbar } = useSnackbar();
  const { getApiInstance } = useApiService();
  const dispatch = useAppDispatch();

  const handleUploadedFileRemoval = async () => {
    dispatch(setReceiptUploadProgress(0));
    setFileName("");
    setFileURL("");

    if (isEdit) {
      if (temporaryReceiptUrl.length > 0) {
        setTemporaryReceiptUrl("");
      }
    } else {
      setFieldValue("receiptUrl", "");
    }
    setUploadStatus("idle");
  };

  useEffect(() => {
    if (isEdit) {
      if (fileName.length > 0) {
        dispatch(setIsReceiptAttached(true));
      } else {
        dispatch(setIsReceiptAttached(false));
      }
    }
  }, [fileName]);

  useEffect(() => {
    if (isEdit) {
      setFileName(values.receiptUrl!);
      dispatch(getReceiptUrl({ apiInstance: getApiInstance(), receiptUrl: values.receiptUrl })).then((action) => {
        if (action.meta.requestStatus === "fulfilled") {
          const receiptDetails = action.payload as ReceiptDetails;
          setFileURL(receiptDetails.generatedUrl ?? "");
        } else {
          setFileURL("");
        }
      });
      setUploadStatus("completed");
    }
    const intervalId = setInterval(() => {
      setDots((prevDots) => (prevDots + 1) % 4);
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <Dropzone
        disabled={disabled}
        onDrop={async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
          if (fileRejections.length > 1) {
            enqueueSnackbar("Unable to upload more than one file at a time", {
              variant: "error",
            });
          } else if (fileRejections.length === 1) {
            const { code } = fileRejections[0].errors[0];

            if (code === "file-invalid-type") {
              enqueueSnackbar("Invalid file type. Please upload a JPG, PNG or PDF file", {
                variant: "error",
              });
            } else if (code === "file-too-large") {
              enqueueSnackbar("File exceeds the maximum allowed size limit", {
                variant: "error",
              });
            } else {
              enqueueSnackbar("Failed to upload receipt. Please try again", {
                variant: "error",
              });
            }
          } else {
            setUploadStatus("processing");
            let [acceptedFile] = acceptedFiles;

            if (acceptedFile.type != "application/pdf") {
              acceptedFile = await imageCompression(acceptedFile, {
                maxSizeMB: maxFileSize,
              });
            }

            dispatch(uploadReceipt({ apiInstance: getApiInstance(), file: acceptedFile, userEmail })).then((action) => {
              if (action.meta.requestStatus === "fulfilled") {
                const receiptUrl = action.payload as string;

                if (isEdit) {
                  dispatch(setTemporaryReceiptUrl(receiptUrl));
                } else {
                  setFieldValue("receiptUrl", receiptUrl);
                }
                setFileName(acceptedFile.name);
                const url = URL.createObjectURL(acceptedFile);
                setFileURL(url);
                setUploadStatus("completed");
              } else {
                setUploadStatus("failed");
              }
            });
          }
        }}
        noClick={uploadStatus === "processing" || uploadStatus === "completed"}
        noDrag={uploadStatus === "processing" || uploadStatus === "completed"}
        maxSize={maxFileSize * (1024 * 1024)}
        accept={{ "image/png": [], "image/jpeg": [], "application/pdf": [] }}
        multiple={false}
      >
        {({ getRootProps, getInputProps }) => (
          <Box
            {...getRootProps()}
            sx={{
              border: "1.5px dashed",
              borderRadius: "5px",
              paddingY: "15px",
              paddingX: "15px",
              bgcolor: disabled ? "disable.main" : "inherit",
            }}
          >
            {uploadStatus === "idle" || uploadStatus === "failed" ? (
              <>
                <input {...getInputProps()} />
                <Stack direction="row" alignItems={"center"} justifyContent={"center"} spacing={2}>
                  <FileUploadIcon sx={{ fontSize: 30 }} />
                  <Typography fontSize={15} fontWeight={500}>
                    Drag & drop your receipt or{" "}
                    <span style={{ color: theme.palette.blue[500], textDecoration: "underline", cursor: "pointer" }}>
                      browse
                    </span>
                  </Typography>
                </Stack>
              </>
            ) : (
              <Zoom in={true}>
                <Stack direction={"row"} alignItems={"center"}>
                  <FileIcon sx={{ fontSize: 30 }} />
                  <Box width={"15px"} />
                  <Stack
                    width={"100%"}
                    alignItems={"start"}
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {uploadStatus === "processing" ? (
                      <>
                        <Typography fontWeight={500} fontSize={14}>
                          {`File upload in progress ${".".repeat(dots)}`}
                        </Typography>
                        <Box height={"5px"} />
                        <LinearProgress
                          variant={"determinate"}
                          value={receiptUploadProgress}
                          color="primary"
                          sx={{ width: "100%" }}
                        />
                      </>
                    ) : (
                      <a
                        style={{ width: "100%" }}
                        onClick={() => {
                          if (fileURL) {
                            const link = document.createElement("a");
                            link.href = fileURL;
                            link.target = "_blank";
                            link.click();
                          }
                        }}
                      >
                        <Typography
                          fontSize={14}
                          fontWeight={500}
                          color={"blue.500"}
                          sx={{
                            cursor: fileURL ? "pointer" : "auto",
                            textDecoration: "underline",
                            width: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                          title={fileName}
                        >
                          {fileName}
                        </Typography>
                      </a>
                    )}
                  </Stack>
                  <Box width={"10px"} />
                  {uploadStatus === "completed" && !disabled && (
                    <>
                      <Box width={5} />
                      <CancelIcon
                        sx={{ fontSize: "18px", cursor: "pointer" }}
                        color={"error"}
                        onClick={handleUploadedFileRemoval}
                      />
                    </>
                  )}
                </Stack>
              </Zoom>
            )}
          </Box>
        )}
      </Dropzone>
      <Typography fontSize={11}>{`JPG, PNG or PDF, file size no more than ${maxFileSize}MB`}</Typography>
    </>
  );
};
