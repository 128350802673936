import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AppData, Employee, View } from "../../utils/types";
import { AxiosInstance } from "axios";
import { setDraftClaimItems } from "./newClaimSlice";
import { enqueueSnackbar } from "notistack";

interface AppInfo {
  userEmail: string;
  userImage: string | null;
  jobNumbers: string[];
  reimbursementCurrency: string;
  employees: Employee[];
  leadEmail: string | null;
  leadName: string;
  accessibleViews: View[];
  isErrorDialogOpen: boolean;
  pastDateRestrictionDays: number | null;
}

const initialState: AppInfo = {
  userEmail: "",
  userImage: "",
  jobNumbers: [],
  reimbursementCurrency: "",
  employees: [],
  leadEmail: "",
  leadName: "",
  accessibleViews: [View.USER],
  isErrorDialogOpen: false,
  pastDateRestrictionDays: null,
};

export const getAppData = createAsyncThunk(
  "appData/getAppData",
  (payload: { apiInstance: AxiosInstance }, { dispatch }) => {
    return new Promise<AppData>((resolve, reject) => {
      payload.apiInstance
        .get("/app-data")
        .then((resp) => {
          const appData: AppData = resp.data;

          if (appData.draft && appData.draft.transactions.length > 0) {
            dispatch(setDraftClaimItems(appData.draft.transactions));
          }
          resolve(appData);
        })
        .catch(() => {
          enqueueSnackbar(
            "Error occurred while fetching app data. Please try again. If the issue persists, contact Internal Apps Team",
            { variant: "error" }
          );
          reject();
        });
    });
  }
);

export const getEmployees = createAsyncThunk(
  "appData/getEmployees",
  async (payload: { apiInstance: AxiosInstance }) => {
    return new Promise<Employee[]>((resolve, reject) => {
      payload.apiInstance
        .get("/employees")
        .then((resp) => {
          const employees: Employee[] = resp.data;
          resolve(employees);
        })
        .catch(() => {
          enqueueSnackbar(
            "Error occurred while fetching employees. Please try again. If the issue persists, contact Internal Apps Team",
            { variant: "error" }
          );
          reject();
        });
    });
  }
);

export const appDataSlice = createSlice({
  name: "appData",
  initialState,
  reducers: {
    setIsErrorDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isErrorDialogOpen = action.payload;
    },
    setLeadName: (state, action: PayloadAction<string>) => {
      state.leadName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAppData.fulfilled, (state, action) => {
        state.userEmail = action.payload.userInfo.workEmail;
        state.userImage = action.payload.userInfo.employeeThumbnail;

        if (action.payload.enableFinanceView) {
          state.accessibleViews.push(View.FINANCE);
        }
        if (action.payload.enableLeadView) {
          state.accessibleViews.push(View.LEAD);
        }

        state.leadEmail = action.payload.userInfo.managerEmail;
        state.jobNumbers = ["N/A", ...action.payload.travels.map((travel: any) => travel.jobNumber)];
        state.reimbursementCurrency = action.payload.currencyCode;
        state.pastDateRestrictionDays = action.payload.pastDateRestrictionDays;
      })
      .addCase(getAppData.rejected, (state) => {
        state.isErrorDialogOpen = true;
      });
    builder.addCase(getEmployees.fulfilled, (state, action) => {
      state.employees = action.payload;
    });
  },
});

export const { setIsErrorDialogOpen, setLeadName } = appDataSlice.actions;

export default appDataSlice.reducer;
