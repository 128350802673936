import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ButtonState } from "../../utils/types";

interface Common {
  confirmationButtonState: ButtonState;
}

const initialState: Common = {
  confirmationButtonState: ButtonState.ACTIVE,
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setConfirmationButtonState: (state, action: PayloadAction<ButtonState>) => {
      state.confirmationButtonState = action.payload;
    },
  },
});

export const { setConfirmationButtonState } = commonSlice.actions;

export default commonSlice.reducer;
