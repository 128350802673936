import { configureStore } from "@reduxjs/toolkit";
import appDataReducer from "./slices/appDataSlice";
import newClaimReducer from "./slices/newClaimSlice";
import FilterReducer from "./slices/filterSlice";
import TableReducer from "./slices/tableSlice";
import ClaimDetailsReducer from "./slices/claimDetailsSlice";
import FormReducer from "./slices/formSlice";
import CommonReducer from "./slices/commonSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

export const store = configureStore({
  reducer: {
    appData: appDataReducer,
    newClaim: newClaimReducer,
    filter: FilterReducer,
    table: TableReducer,
    claimDetails: ClaimDetailsReducer,
    form: FormReducer,
    common: CommonReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
