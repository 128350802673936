import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ButtonState,
  Claim,
  ClaimItem,
  ClaimItemPayload,
  ClaimReviewAction,
  ClaimStatus,
  ReceiptDetails,
  ReceiptTypes,
  View,
} from "../../utils/types";
import { AxiosInstance } from "axios";
import { RootState } from "..";
import { setNeedsClaimRefetch, setReviewedClaimDetails } from "./tableSlice";
import { enqueueSnackbar } from "notistack";
import { setConfirmationButtonState } from "./commonSlice";
import { convertToClaimItemPayload } from "../../utils/utils";

interface ClaimDetails {
  selectedClaim: Claim | null;
  resubmitClaimItems: ClaimItem[];
  resubmitClaimItemTotalAmount: number;
  claimRejectionReason: string | null;
  receipts: ReceiptDetails[];
}

const initialState: ClaimDetails = {
  selectedClaim: null,
  resubmitClaimItems: [],
  resubmitClaimItemTotalAmount: 0,
  claimRejectionReason: null,
  receipts: [],
};

const getMappedStatus = (view: View, action: ClaimReviewAction) => {
  if (action === "APPROVED") {
    if (view === View.LEAD) {
      return ClaimStatus.PENDING_FINANCE;
    } else {
      return ClaimStatus.APPROVED;
    }
  } else {
    if (view === View.LEAD) {
      return ClaimStatus.LEAD_REJECTED;
    } else {
      return ClaimStatus.FINANCE_REJECTED;
    }
  }
};

export const updateClaimItems = createAsyncThunk(
  "claimDetails/updateClaimItems",
  (
    payload: {
      apiInstance: AxiosInstance;
    },
    { getState, dispatch }
  ) => {
    const { selectedClaim, resubmitClaimItems } = (getState() as RootState).claimDetails;

    return new Promise<void>((resolve, reject) => {
      const modifiedClaimItems: ClaimItemPayload[] = convertToClaimItemPayload(resubmitClaimItems);

      dispatch(setConfirmationButtonState(ButtonState.LOADING));

      payload.apiInstance
        .put("/claims/" + selectedClaim!.id + "/transactions", modifiedClaimItems)
        .then(() => {
          enqueueSnackbar("Claim resubmitted successfully", { variant: "success" });
          resolve();
          dispatch(setNeedsClaimRefetch(true));
        })
        .catch(() => {
          enqueueSnackbar(
            "Failed to resubmit the claim. Please try again. If the issue persists, contact Internal Apps Team",
            { variant: "error" }
          );
          reject();
        })
        .finally(() => {
          dispatch(setConfirmationButtonState(ButtonState.ACTIVE));
        });
    });
  }
);

export const changeClaimStatus = createAsyncThunk(
  "claimDetails/changeClaimStatus",
  (
    payload: {
      apiInstance: AxiosInstance;
      selectedReviewAction: ClaimReviewAction;
      view: View;
    },
    { getState, dispatch }
  ) => {
    const { selectedClaim, claimRejectionReason } = (getState() as RootState).claimDetails;

    return new Promise<void>((resolve, reject) => {
      dispatch(setConfirmationButtonState(ButtonState.LOADING));

      payload.apiInstance
        .post("/claims/" + selectedClaim!.id + "/status", {
          status: getMappedStatus(payload.view, payload.selectedReviewAction),
          reason: claimRejectionReason ?? undefined,
        })
        .then(() => {
          dispatch(setReviewedClaimDetails({ claim: selectedClaim!, status: payload.selectedReviewAction }));
          enqueueSnackbar(
            `Claim ${
              payload.selectedReviewAction === ClaimReviewAction.APPROVED ? "approved" : "rejected"
            } successfully`,
            { variant: "success" }
          );
          resolve();
        })
        .catch(() => {
          enqueueSnackbar(
            `Unable to ${
              payload.selectedReviewAction === ClaimReviewAction.APPROVED ? "approve" : "reject"
            } claim. Please try again. If the issue persists, contact Internal Apps Team`,
            { variant: "error" }
          );
          reject();
        })
        .finally(() => {
          dispatch(setConfirmationButtonState(ButtonState.ACTIVE));
        });
    });
  }
);

export const getReceiptUrl = createAsyncThunk(
  "claimDetails/getReceiptUrl",
  (payload: { apiInstance: AxiosInstance; receiptUrl: string | null }) => {
    return new Promise<ReceiptDetails>((resolve, reject) => {
      payload.apiInstance
        .get("/claims/transactions/receipts/file/" + payload.receiptUrl, { responseType: "blob" })
        .then((resp) => {
          const receiptType = (resp.data as Blob).type === "application/pdf" ? ReceiptTypes.PDF : ReceiptTypes.IMAGE;
          const generatedUrl = URL.createObjectURL(resp.data);
          resolve({ receiptType, generatedUrl });
        })
        .catch(() => {
          enqueueSnackbar("Failed to load receipt", { variant: "error" });
          reject();
        });
    });
  }
);

export const claimDetailsSlice = createSlice({
  name: "claimDetails",
  initialState,
  reducers: {
    setSelectedClaim: (state, action: PayloadAction<Claim | null>) => {
      state.selectedClaim = action.payload;
    },
    addResubmitClaimItems: (state, action: PayloadAction<ClaimItem>) => {
      state.resubmitClaimItems.push(action.payload);
      state.resubmitClaimItemTotalAmount += action.payload.reimbursementAmount;
    },
    editResubmitClaimItem: (state, action: PayloadAction<{ item: ClaimItem; index: number }>) => {
      state.resubmitClaimItemTotalAmount -= state.resubmitClaimItems[action.payload.index].reimbursementAmount;
      state.resubmitClaimItems.splice(action.payload.index, 1, action.payload.item);
      state.resubmitClaimItemTotalAmount += action.payload.item.reimbursementAmount;
    },
    removeAllResubmitClaimItems: (state) => {
      state.resubmitClaimItems = [];
      state.resubmitClaimItemTotalAmount = 0;
    },
    setClaimRejectionReason: (state, action: PayloadAction<string | null>) => {
      state.claimRejectionReason = action.payload;
    },
    addReceipt: (state, action: PayloadAction<{ index: number; receipt: ReceiptDetails }>) => {
      state.receipts[action.payload.index] = action.payload.receipt;
    },
    resetClaimDetails: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(updateClaimItems.fulfilled, (state) => {
      state.resubmitClaimItems = [];
      state.resubmitClaimItemTotalAmount = 0;
      state.selectedClaim = null;
    });
    builder.addCase(changeClaimStatus.fulfilled, (state) => {
      state.resubmitClaimItems = [];
      state.resubmitClaimItemTotalAmount = 0;
      state.selectedClaim = null;
      state.claimRejectionReason = null;
    });
  },
});

export const {
  setSelectedClaim,
  addResubmitClaimItems,
  editResubmitClaimItem,
  removeAllResubmitClaimItems,
  setClaimRejectionReason,
  addReceipt,
  resetClaimDetails,
} = claimDetailsSlice.actions;

export default claimDetailsSlice.reducer;
