import { Sidebar } from "../components/Sidebar";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { TopStickyBar } from "../components/top-navbar/TopStickyBar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store";
import { PopupTransition } from "../components/PopupTransition";
import ErrorImage from "../assets/images/error.svg";
import { setIsErrorDialogOpen } from "../store/slices/appDataSlice";
import { supportUrl } from "../config";
import { useEffect } from "react";

export const Root = () => {
  const isErrorDialogOpen = useAppSelector((state) => state.appData.isErrorDialogOpen);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const redirectPath = sessionStorage.getItem("redirect-path");
  sessionStorage.removeItem("redirect-path");

  useEffect(() => {
    if (location.pathname === "/") {
      navigate(redirectPath ? (redirectPath === "/" ? "new-claim" : redirectPath) : "new-claim");
    }
  }, []);

  return (
    <>
      <Stack direction={"row"}>
        <Sidebar />
        <Stack sx={{ height: "100vh", width: "100vw" }}>
          <TopStickyBar />
          <Outlet />
        </Stack>
      </Stack>

      {/* Error Dialog Box component for displaying critical errors when they occur*/}
      <Dialog
        open={isErrorDialogOpen}
        TransitionComponent={PopupTransition}
        onClose={() => dispatch(setIsErrorDialogOpen(false))}
        PaperProps={{
          style: {
            borderRadius: 10,
            padding: 6,
          },
        }}
      >
        <DialogTitle>Oops! Something Went Wrong</DialogTitle>
        <DialogContent sx={{ display: "flex", alignItems: "center" }}>
          <Stack>
            <Box height={"30px"} />
            <img src={ErrorImage} alt="Error" height={"200px"} />
            <Box height={"35px"} />
            <Typography fontSize={15}>
              We apologize, but it seems there was an issue processing your request. Please try again. If the issue
              persists, contact Internal Apps Team
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ marginTop: "5px" }}>
          <Button sx={{ color: "text.secondary" }} onClick={() => dispatch(setIsErrorDialogOpen(false))} size="small">
            Cancel
          </Button>
          <Button variant="contained" onClick={() => window.open(supportUrl)} size="small">
            Get Support
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
