import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import BackIcon from "@mui/icons-material/ArrowBack";
import { ClaimItemCard } from "./ClaimItemCard";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  AccessMode,
  ButtonState,
  ClaimActivityValues,
  ClaimItem,
  ClaimReviewAction,
  ClaimStatus,
  Employee,
  View,
} from "../../utils/types";
import {
  areArraysEqual,
  changeStatusLabel,
  getEmployeeDetails,
  getEmployeeName,
  getStatusColor,
} from "../../utils/utils";
import { Circle } from "@mui/icons-material";
import { useEffect, useState } from "react";
import ForwardArrowIcon from "@mui/icons-material/ArrowForwardIos";
import {
  addResubmitClaimItems,
  changeClaimStatus,
  removeAllResubmitClaimItems,
  resetClaimDetails,
  setClaimRejectionReason,
  setSelectedClaim,
  updateClaimItems,
} from "../../store/slices/claimDetailsSlice";
import { ConfirmationDialog } from "../ConfirmationDialog";
import { useApiService } from "../../utils/apiService";
import { theme } from "../../theme";
import { AmountFooter } from "./AmountFooter";
import ClaimActivityImage from "../../assets/images/claim-activity.svg";
import ClaimSubmissionIcon from "@mui/icons-material/ListAlt";
import LeadIcon from "@mui/icons-material/Person4Outlined";
import FinanceIcon from "@mui/icons-material/Groups3Outlined";
import CloseIcon from "@mui/icons-material/Close";
import PrintIcon from "@mui/icons-material/PrintOutlined";
import { Timeline, timelineItemClasses } from "@mui/lab";
import { CustomTimelineItem } from "./CustomTimelineItem";
import { usePDF } from "@react-pdf/renderer";
import { ReportTemplate } from "./ReportTemplate";

interface ClaimDetailsProps {
  view: View;
  isPendingClaims?: boolean;
}

export const ClaimDetails: React.FC<ClaimDetailsProps> = ({ view, isPendingClaims }) => {
  const employees = useAppSelector((state) => state.appData.employees);
  const selectedClaim = useAppSelector((state) => state.claimDetails.selectedClaim)!;
  const resubmitClaimItems = useAppSelector((state) => state.claimDetails.resubmitClaimItems);
  const confirmationButtonState = useAppSelector((state) => state.common.confirmationButtonState);
  const receipts = useAppSelector((state) => state.claimDetails.receipts);
  const totalReimbursementAmount = useAppSelector((state) =>
    resubmitClaimItems.length > 0 ? state.claimDetails.resubmitClaimItemTotalAmount : selectedClaim.totalAmount
  );

  const [openApprovalConfirmation, setOpenApprovalConfirmation] = useState(false);
  const [openResubmissionConfirmation, setOpenResubmissionConfirmation] = useState(false);
  const [openDiscardConfirmation, setOpenDiscardConfirmation] = useState(false);
  const [selectedReviewAction, setSelectedReviewAction] = useState<ClaimReviewAction>();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [employeeDetails, setEmployeeDetails] = useState<Employee | null>(null);
  const [employeeEmail, setEmployeeEmail] = useState<string>("");
  const [isResubmissionAvailable, setIsResubmissionAvailable] = useState<boolean>();
  const [isConvertingReceipts, setIsConvertingReceipts] = useState<boolean>(false);
  const [isReceiptDownloadClicked, setIsReceiptDownloadClicked] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { getApiInstance } = useApiService();
  const [instance, updateInstance] = usePDF();

  const handleApprovalConfirmationOpen = (action: ClaimReviewAction) => {
    setSelectedReviewAction(action);
    setOpenApprovalConfirmation(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const getConfirmationContent = (type: "confirmation" | "resubmission") => {
    if (type === "resubmission") {
      if (areArraysEqual(selectedClaim.transactions, resubmitClaimItems)) {
        return "You haven't changed any claim items. Are you sure you want to resubmit?";
      } else {
        return "Are you sure you want to resubmit the claim?";
      }
    } else {
      if (view === View.FINANCE) {
        return "You're performing this action as a Finance lead. Are you sure you want to proceed?";
      } else {
        if (selectedReviewAction === ClaimReviewAction.REJECTED) {
          return "You're performing this action as a Lead";
        }
        return "You're performing this action as a Lead. Are you sure you want to proceed?";
      }
    }
  };

  useEffect(() => {
    if (
      view === View.USER &&
      (selectedClaim.statusDetails.status === ClaimStatus.LEAD_REJECTED ||
        selectedClaim.statusDetails.status === ClaimStatus.FINANCE_REJECTED)
    ) {
      setIsResubmissionAvailable(true);
      selectedClaim.transactions.map((claimItem: ClaimItem) => {
        dispatch(addResubmitClaimItems(claimItem));
      });
    } else {
      setIsResubmissionAvailable(false);
    }

    if (view === View.USER) {
      if (selectedClaim.leadEmails && selectedClaim.leadEmails.length > 0) {
        setEmployeeEmail(selectedClaim.leadEmails[0]);
        setEmployeeDetails(getEmployeeDetails(selectedClaim.leadEmails[0], employees));
      }
    } else {
      setEmployeeEmail(selectedClaim.employeeEmail);
      setEmployeeDetails(getEmployeeDetails(selectedClaim.employeeEmail, employees));
    }
  }, []);

  useEffect(() => {
    if (isReceiptDownloadClicked) {
      updateInstance(
        <ReportTemplate
          claim={selectedClaim}
          receiptsDetails={receipts}
          setIsConvertingReceipts={setIsConvertingReceipts}
        />
      );
    }
  }, [isReceiptDownloadClicked]);

  useEffect(() => {
    if (!(instance.loading || isConvertingReceipts) && instance.url && isReceiptDownloadClicked) {
      const link = document.createElement("a");
      link.href = instance.url;
      link.download = `${selectedClaim.id}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsReceiptDownloadClicked(false);
    }
  }, [instance, isReceiptDownloadClicked]);

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
        <Stack direction={"row"} alignItems={"center"}>
          <IconButton
            onClick={() => {
              if (!isResubmissionAvailable || areArraysEqual(selectedClaim.transactions, resubmitClaimItems)) {
                dispatch(resetClaimDetails());
              } else {
                setOpenDiscardConfirmation(true);
              }
            }}
          >
            <BackIcon />
          </IconButton>
          <Box width={"5px"} />
          <Typography fontSize={20} fontWeight={600}>
            {selectedClaim.id}
          </Typography>
          <Box width={"40px"} />
          <Tooltip title={employeeEmail} arrow>
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                border: `1px solid ${theme.palette.border.main}`,
                borderTopRightRadius: "20px",
                borderBottomRightRadius: "20px",
              }}
              borderRadius={"3px"}
              height={"100%"}
              spacing={2.2}
            >
              <Stack direction={"row"}>
                <Typography paddingX={"10px"} fontSize={"14px"} fontWeight={500}>
                  {view === View.USER ? "Lead" : "Employee"}
                </Typography>
                <Divider
                  orientation="vertical"
                  sx={{
                    borderRightWidth: 2,
                    height: 20,
                  }}
                />
              </Stack>
              <Typography color={"text.secondary"} fontSize={"14px"}>
                {(employeeDetails && getEmployeeName(employeeDetails)) || employeeEmail}
              </Typography>
              <Avatar
                src={employeeDetails?.employeeThumbnail || undefined}
                sx={{ width: "34px", height: "34px" }}
                alt={employeeDetails?.firstName || employeeEmail}
              />
            </Stack>
          </Tooltip>
        </Stack>
        {view === View.USER && isResubmissionAvailable && (
          <Button
            variant="contained"
            color="primary"
            sx={{ height: "100%" }}
            onClick={() => setOpenResubmissionConfirmation(true)}
          >
            Resubmit
          </Button>
        )}
        {(view === View.LEAD || view === View.FINANCE) && (
          <Stack direction={"row"}>
            {view === View.FINANCE && (
              <>
                <IconButton
                  sx={{
                    bgcolor: "grey.500",
                    color: "white",
                    "&:hover": {
                      bgcolor: "text.primary",
                    },
                  }}
                  onClick={() => {
                    if (
                      receipts.length === selectedClaim.transactions.length &&
                      receipts.every((receipt) => receipt != null)
                    ) {
                      setIsReceiptDownloadClicked(true);
                    }
                  }}
                >
                  {receipts.length === selectedClaim.transactions.length &&
                  receipts.every((receipt) => receipt != null) &&
                  !isReceiptDownloadClicked ? (
                    <PrintIcon sx={{ fontSize: "20px" }} />
                  ) : (
                    <CircularProgress size={19} disableShrink sx={{ color: "white" }} />
                  )}
                </IconButton>

                <Box width={"25px"} />
              </>
            )}
            {isPendingClaims ? (
              <Stack direction={"row"}>
                <Box>
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ height: "100%" }}
                    onClick={() => handleApprovalConfirmationOpen(ClaimReviewAction.APPROVED)}
                  >
                    Approve
                  </Button>
                </Box>
                <Box width={"15px"} />
                <Box>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ height: "100%" }}
                    onClick={() => handleApprovalConfirmationOpen(ClaimReviewAction.REJECTED)}
                  >
                    Reject
                  </Button>
                </Box>
              </Stack>
            ) : (
              <Button
                variant="contained"
                size="small"
                sx={{
                  ...getStatusColor(selectedClaim.statusDetails.status),
                  textTransform: "none",
                  borderRadius: 1,
                  boxShadow: 1,
                  "&:hover": {
                    bgcolor: getStatusColor(selectedClaim.statusDetails.status, true).bgcolor,
                  },
                }}
                onClick={() => {
                  setDrawerOpen(true);
                }}
              >
                <Stack direction={"row"} alignItems="center" spacing={1}>
                  <Circle sx={{ width: "10px", height: "10px" }} />
                  <Typography fontSize={"13px"}>{changeStatusLabel(selectedClaim.statusDetails.status)}</Typography>
                  <ForwardArrowIcon sx={{ fontSize: 13 }} />
                </Stack>
              </Button>
            )}
          </Stack>
        )}
      </Stack>
      <Box height={"10px"} />
      <>
        <Box sx={{ overflowY: "auto", marginY: "15px", paddingX: "3px", paddingY: "5px", height: "100%" }}>
          <Stack>
            {isResubmissionAvailable
              ? resubmitClaimItems.map((claimItem, index) => (
                  <ClaimItemCard
                    claimItem={claimItem}
                    accessMode={AccessMode.EDIT_ONLY}
                    index={index}
                    key={index}
                    view={view}
                  ></ClaimItemCard>
                ))
              : selectedClaim.transactions.map((claimItem, index) => (
                  <ClaimItemCard
                    claimItem={claimItem}
                    accessMode={AccessMode.VIEW_ONLY}
                    index={index}
                    key={index}
                    view={view}
                  ></ClaimItemCard>
                ))}
          </Stack>
        </Box>
        <AmountFooter
          reimbursementAmount={totalReimbursementAmount}
          reimbursementCurrency={selectedClaim.currencyCode}
        />
      </>

      <ConfirmationDialog
        isOpen={openApprovalConfirmation}
        isLoading={confirmationButtonState === ButtonState.LOADING}
        isReject={selectedReviewAction === ClaimReviewAction.REJECTED && view === View.LEAD}
        dialogTitle={
          selectedReviewAction === ClaimReviewAction.APPROVED ? "Approve Confirmation" : "Reject Confirmation"
        }
        dialogContent={getConfirmationContent("confirmation")}
        confirmButtonLabel={selectedReviewAction === ClaimReviewAction.APPROVED ? "Approve" : "Reject"}
        confirmButtonColor={selectedReviewAction === ClaimReviewAction.APPROVED ? "success" : "error"}
        handleConfirm={() => {
          dispatch(
            changeClaimStatus({
              apiInstance: getApiInstance(),
              selectedReviewAction: selectedReviewAction!,
              view,
            })
          ).then((action) => {
            if (action.meta.requestStatus === "fulfilled") {
              setOpenApprovalConfirmation(false);
            }
          });
        }}
        handleClose={() => {
          dispatch(setClaimRejectionReason(null));
          setOpenApprovalConfirmation(false);
        }}
      />
      <ConfirmationDialog
        isOpen={openResubmissionConfirmation}
        isLoading={confirmationButtonState === ButtonState.LOADING}
        dialogTitle="Claim Resubmission Confirmation"
        dialogContent={getConfirmationContent("resubmission")}
        confirmButtonLabel="Resubmit"
        confirmButtonColor="success"
        handleConfirm={() => {
          dispatch(
            updateClaimItems({
              apiInstance: getApiInstance(),
            })
          ).then((action) => {
            if (action.meta.requestStatus === "fulfilled") {
              setOpenResubmissionConfirmation(false);
            }
          });
        }}
        handleClose={() => setOpenResubmissionConfirmation(false)}
      />
      <ConfirmationDialog
        isOpen={openDiscardConfirmation}
        dialogTitle="Edit Discard Confirmation"
        dialogContent="Your edit changes will be discarded"
        confirmButtonLabel="Confirm"
        handleConfirm={() => {
          setOpenDiscardConfirmation(false);
          dispatch(setSelectedClaim(null));
          dispatch(removeAllResubmitClaimItems());
        }}
        handleClose={() => setOpenDiscardConfirmation(false)}
      />

      <Drawer
        open={drawerOpen}
        anchor="right"
        onClose={handleDrawerClose}
        sx={{
          "& .MuiDrawer-paper": {
            width: "350px",
            boxSizing: "border-box",
            bgcolor: "white",
            paddingLeft: "30px",
            paddingTop: "20px",
          },
        }}
      >
        <Stack direction={"row"} justifyContent={"space-between"} paddingRight={"15px"} alignItems={"center"}>
          <Typography fontSize={"20px"} fontWeight={600}>
            Claim Activity
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <img src={ClaimActivityImage} width={"220px"} style={{ marginTop: "45px", marginBottom: "30px" }} />
        {selectedClaim && (
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            <CustomTimelineItem
              label={ClaimActivityValues.CLAIM_SUBMISSION}
              Icon={ClaimSubmissionIcon}
              selectedClaim={selectedClaim}
            />
            <CustomTimelineItem label={ClaimActivityValues.LEAD_REVIEW} Icon={LeadIcon} selectedClaim={selectedClaim} />
            <CustomTimelineItem
              label={ClaimActivityValues.FINANCE_REVIEW}
              Icon={FinanceIcon}
              selectedClaim={selectedClaim}
              isLastItem={true}
            />
          </Timeline>
        )}
      </Drawer>
    </>
  );
};
