import {
  Autocomplete,
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  ListItem,
  Menu,
  Paper,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FilterBox } from "./FilterBox";
import { format } from "date-fns";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import { useEffect, useRef, useState } from "react";
import { DateRange, Range } from "react-date-range";
import FilterIcon from "@mui/icons-material/FilterAltOutlined";
import DropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClaimIdSearchIcon from "@mui/icons-material/ContentPasteSearchOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import { ClaimRangeDropdownValues, ClaimStatus, CustomFilter, View } from "../../utils/types";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  setFilteringClaimRange,
  setFilteringData,
  setFilteringClaimStatus,
  setFilteringDateRange,
} from "../../store/slices/filterSlice";
import { theme } from "../../theme";

export const FilterHolder: React.FC<{ view: View }> = ({ view }) => {
  const isClaimRangeVisible = useAppSelector((state) => state.filter.isClaimRangeVisible);
  const selectedClaimRange = useAppSelector((state) => state.filter.filteringClaimRange);
  const selectedClaimStatus = useAppSelector((state) => state.filter.filteringClaimStatus);
  const selectedCustomDateRange = useAppSelector((state) => state.filter.filteringDateRange);
  const filteringData = useAppSelector((state) => state.filter.filteringData);
  const employeeEmails: string[] = useAppSelector((state) => state.appData.employees).map(
    (employee) => employee.workEmail
  );

  const [tempFilteringData, setTempFilteringData] = useState<CustomFilter>({ email: "", claimId: "" });
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dateRange, setDateRange] = useState<Range[]>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const cardRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const open = Boolean(anchorEl);

  const handleFiltersMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setTempFilteringData(filteringData);
  };

  const handleFiltersApply = () => {
    dispatch(setFilteringData(tempFilteringData));
    setAnchorEl(null);
  };
  const handleClaimRangeChange = (event: SelectChangeEvent) => {
    if (event.target.value == ClaimRangeDropdownValues.CUSTOM_DATE) {
      setIsCalendarVisible(true);
    } else {
      dispatch(setFilteringDateRange(null));
      setDateRange([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);
      dispatch(setFilteringClaimRange(event.target.value as ClaimRangeDropdownValues));
    }
  };
  const handleClaimStatusChange = (event: SelectChangeEvent) => {
    dispatch(setFilteringClaimStatus(event.target.value === "All" ? [] : [event.target.value as ClaimStatus]));
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
        setIsCalendarVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [cardRef]);

  return (
    <Stack direction={"row"}>
      {isClaimRangeVisible && (
        <Stack direction={"row"} position={"relative"}>
          <FilterBox
            filterLabel="Claim Range"
            menuItems={Object.values(ClaimRangeDropdownValues)}
            handleValueSelection={handleClaimRangeChange}
            selectedValue={selectedClaimRange}
          />
          {selectedCustomDateRange != null && (
            <>
              <Box width={"10px"} />
              <div onClick={() => setIsCalendarVisible(true)} style={{ cursor: "pointer" }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  border={`1px solid ${theme.palette.border.main}`}
                  paddingX={"10px"}
                  paddingLeft={"15px"}
                  sx={{ height: "100%" }}
                  borderRadius={"3px"}
                >
                  <Typography fontSize={"14px"}>
                    {selectedCustomDateRange != null
                      ? selectedCustomDateRange.startDate + " ~ " + selectedCustomDateRange.endDate
                      : "YYYY/MM/DD ~ YYYY/MM/DD"}
                  </Typography>
                  <Box width={"10px"} />
                  <CalendarIcon sx={{ height: "18px" }}></CalendarIcon>
                </Stack>
              </div>
            </>
          )}
          {isCalendarVisible && (
            <Card
              sx={{ position: "absolute", top: 40, right: 0, zIndex: 5 }}
              variant="elevation"
              elevation={5}
              ref={cardRef}
            >
              <Stack>
                <DateRange
                  editableDateInputs={true}
                  onChange={(item) => setDateRange([item.selection])}
                  moveRangeOnFirstSelection={false}
                  ranges={dateRange}
                  maxDate={new Date()}
                />
                <Divider />
                <Button
                  variant="contained"
                  sx={{
                    marginX: "20px",
                    marginY: "15px",
                  }}
                  onClick={() => {
                    dispatch(
                      setFilteringDateRange({
                        startDate: format(dateRange[0].startDate!, "yyyy-MM-dd"),
                        endDate: format(dateRange[0].endDate!, "yyyy-MM-dd"),
                      })
                    );
                    setIsCalendarVisible(false);
                    dispatch(setFilteringClaimRange(ClaimRangeDropdownValues.CUSTOM_DATE));
                  }}
                >
                  Apply
                </Button>
              </Stack>
            </Card>
          )}
        </Stack>
      )}
      {view === View.USER && (
        <>
          <Box width={"30px"} />
          <FilterBox
            filterLabel="Status"
            menuItems={Object.values(ClaimStatus)}
            handleValueSelection={handleClaimStatusChange}
            selectedValue={(selectedClaimStatus && selectedClaimStatus[0]) ?? "All"}
            isStatusFilter={true}
          />
        </>
      )}
      <Box width={"30px"} />
      <Button
        variant="outlined"
        startIcon={<FilterIcon />}
        endIcon={<DropDownIcon />}
        onClick={handleFiltersMenuOpen}
        sx={{
          border: `1px solid ${theme.palette.border.main}`,
          color: "text.secondary",
          fontWeight: 400,
          textTransform: "none",
          "&:hover": {
            border: `1px solid ${theme.palette.border.main}`,
            bgcolor: "white",
          },
        }}
      >
        Filters
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        <ListItem
          sx={{
            paddingX: "20px",
            paddingY: "10px",
          }}
        >
          <Stack>
            <Typography fontSize={"18px"} fontWeight={600}>
              Filters
            </Typography>
            <Box height={"20px"} width={"300px"} />
            {(view === View.FINANCE || view === View.LEAD) && (
              <>
                <Autocomplete
                  id="filterEmail"
                  options={employeeEmails}
                  size="small"
                  onChange={(_event, value) => setTempFilteringData((prev) => ({ ...prev, email: value ?? "" }))}
                  value={tempFilteringData.email || null}
                  PaperComponent={({ children }) => (
                    <Paper style={{ fontSize: 14 }} elevation={6}>
                      {children}
                    </Paper>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Filter by email"
                      fullWidth
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: "20px",
                        },
                      }}
                    />
                  )}
                />
                <Box height={"18px"} />
              </>
            )}
            <TextField
              id="filterClaimId"
              label="Filter by claim ID"
              size="small"
              fullWidth
              value={tempFilteringData.claimId}
              onChange={(event) => setTempFilteringData((prev) => ({ ...prev, claimId: event.target.value }))}
              sx={{
                ".MuiOutlinedInput-root": {
                  paddingRight: "9px",
                },
                "& .MuiSvgIcon-root": {
                  fontSize: "20px",
                  padding: "4px",
                },
              }}
              InputProps={{
                endAdornment:
                  tempFilteringData.claimId.length > 0 ? (
                    <IconButton onClick={() => setTempFilteringData((prev) => ({ ...prev, claimId: "" }))}>
                      <ClearIcon />
                    </IconButton>
                  ) : (
                    <ClaimIdSearchIcon />
                  ),
              }}
            />
            <Box height={"40px"} />
            <Stack direction={"row"} justifyContent="flex-end">
              <Button size="small" color="secondary" onClick={() => setAnchorEl(null)}>
                Cancel
              </Button>
              <Box width={"15px"} />
              <Button variant="contained" size="small" onClick={handleFiltersApply}>
                Apply
              </Button>
            </Stack>
          </Stack>
        </ListItem>
      </Menu>
    </Stack>
  );
};
