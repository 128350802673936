import { AuthReactConfig } from "@asgardeo/auth-react";

declare global {
  interface Window {
    config: {
      ASGARDEO_BASE_URL: string;
      AUTH_SIGN_IN_REDIRECT_URL: string;
      AUTH_SIGN_OUT_REDIRECT_URL: string;
      ASGARDEO_CLIENT_ID: string;
      BACKEND_BASE_URL: string;
      APP_NAME: string;
      SUPPORT_EMAIL_RECIPIENT: string;
      SUPPORT_EMAIL_SUBJECT: string;
    };
  }
}

export const appName: string = `${window.config.APP_NAME}`;

export const asgardeoConfig: AuthReactConfig = {
  signInRedirectURL: `${window.config.AUTH_SIGN_IN_REDIRECT_URL}`,
  signOutRedirectURL: `${window.config.AUTH_SIGN_OUT_REDIRECT_URL}`,
  clientID: `${window.config.ASGARDEO_CLIENT_ID}`,
  baseUrl: `${window.config.ASGARDEO_BASE_URL}`,
  scope: ["openid", "email", "groups"],
  disableAutoSignIn: false,
};

export const supportUrl = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${window.config.SUPPORT_EMAIL_RECIPIENT}&su=${window.config.SUPPORT_EMAIL_SUBJECT}`;

export const serviceBaseUrl = `${window.config.BACKEND_BASE_URL}`;
