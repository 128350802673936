import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Claim, ClaimRangeDropdownValues, ClaimReviewAction, View } from "../../utils/types";
import axios, { AxiosInstance } from "axios";
import { RootState } from "..";
import { enqueueSnackbar } from "notistack";
import { setIsErrorDialogOpen } from "./appDataSlice";

interface ClaimTable {
  claims: Claim[];
  reviewedClaimDetails: {
    claim: Claim;
    status: ClaimReviewAction;
  } | null;
  needsClaimRefetch: boolean;
  isClaimDataFetching: boolean;
}

const initialState: ClaimTable = {
  claims: [],
  reviewedClaimDetails: null,
  needsClaimRefetch: false,
  isClaimDataFetching: false,
};

export const fetchClaimsData = createAsyncThunk(
  "filter/fetchClaimsData",
  (
    payload: {
      apiInstance: AxiosInstance;
      signal: AbortSignal;
      view: View;
      isFetchingFromApi: React.MutableRefObject<boolean>;
    },
    { getState, dispatch }
  ) => {
    const { filteringData, filteringClaimRange, isClaimRangeVisible, filteringDateRange, filteringClaimStatus } = (
      getState() as RootState
    ).filter;
    const { userEmail } = (getState() as RootState).appData;

    return new Promise<Claim[]>((resolve, reject) => {
      payload.apiInstance
        .post(
          "/search-claims",
          {
            email: filteringData.email || (payload.view === View.USER ? userEmail : undefined),
            ids: filteringData.claimId ? [filteringData.claimId] : undefined,
            limit: filteringClaimRange === ClaimRangeDropdownValues.LATEST_100 && isClaimRangeVisible ? 100 : undefined,
            startDate: filteringDateRange ? filteringDateRange.startDate : undefined,
            endDate: filteringDateRange ? filteringDateRange.endDate : undefined,
            status: filteringClaimStatus.length > 0 ? filteringClaimStatus : undefined,
            leadEmail: payload.view === View.LEAD ? userEmail : undefined,
          },
          { signal: payload.signal }
        )
        .then((resp) => {
          const claims: Claim[] = resp.data;

          payload.isFetchingFromApi.current = false;
          resolve(claims);
        })
        .catch((error: Error) => {
          if (!axios.isCancel(error)) {
            enqueueSnackbar(
              "Error retrieving claims. Please try again. If the issue persists, contact Internal Apps Team",
              { variant: "error" }
            );
            dispatch(setIsErrorDialogOpen(true));
          }
          reject(error);
        });
    });
  }
);

export const tableSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setClaims: (state, action: PayloadAction<Claim[]>) => {
      state.claims = action.payload;
    },
    setReviewedClaimDetails: (state, action: PayloadAction<{ claim: Claim; status: ClaimReviewAction } | null>) => {
      state.reviewedClaimDetails = action.payload;
    },
    setNeedsClaimRefetch: (state, action: PayloadAction<boolean>) => {
      state.needsClaimRefetch = action.payload;
    },
    setIsClaimDataFetching: (state, action: PayloadAction<boolean>) => {
      state.isClaimDataFetching = action.payload;
    },
    resetTableData: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClaimsData.pending, (state) => {
        state.isClaimDataFetching = true;
      })
      .addCase(fetchClaimsData.fulfilled, (state, action) => {
        state.claims = action.payload;
        state.isClaimDataFetching = false;
      })
      .addCase(fetchClaimsData.rejected, (state, action) => {
        if (action.error.code !== "ERR_CANCELED") {
          state.claims = [];
          state.isClaimDataFetching = false;
        }
      });
  },
});

export const { setClaims, setReviewedClaimDetails, setNeedsClaimRefetch, setIsClaimDataFetching, resetTableData } =
  tableSlice.actions;

export default tableSlice.reducer;
