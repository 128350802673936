import { useField, useFormikContext } from "formik";
import dayjs from "dayjs";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { ListItem, Menu, TextField } from "@mui/material";
import { Calendar } from "react-date-range";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
import { useAppDispatch, useAppSelector } from "../../store";
import { setSelectedBillDate } from "../../store/slices/formSlice";

interface CustomDatePickerProps {
  id: string;
  label: string;
  disabled?: boolean;
  submittedDate?: string;
}

export const CustomDatePicker: React.FC<CustomDatePickerProps> = ({ id, label, disabled, submittedDate }) => {
  const pastDateRestrictionDays = useAppSelector((state) => state.appData.pastDateRestrictionDays);
  const [focused, setFocused] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(id);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!disabled) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setFocused(true);
    setAnchorEl(null);
  };

  const dispatch = useAppDispatch();

  return (
    <>
      <TextField
        label={label}
        required
        value={field.value ?? null}
        fullWidth
        disabled={disabled}
        size="small"
        sx={{
          svg: { scale: "0.8" },
          ...(disabled && {
            ".MuiOutlinedInput-root": {
              bgcolor: "disable.main",
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "disable.dark",
              },
            },
          }),
        }}
        onClick={handleClick}
        inputProps={{ readOnly: true }}
        focused={false}
        error={meta && focused && meta.error ? true : false}
        helperText={meta && focused && meta.error ? meta.error : " "}
        InputProps={{
          endAdornment: <CalendarIcon sx={{ cursor: disabled ? "auto" : "pointer" }} />,
        }}
      ></TextField>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} sx={{ top: "-15px" }} elevation={10}>
        <ListItem>
          <Calendar
            date={field.value ? dayjs(field.value).toDate() : undefined}
            maxDate={new Date()}
            onChange={async (value) => {
              const formattedDate = dayjs(value).format("YYYY-MM-DD");
              if (
                pastDateRestrictionDays &&
                dayjs(formattedDate).isBefore(
                  (submittedDate ? dayjs(submittedDate) : dayjs()).subtract(pastDateRestrictionDays, "days")
                )
              ) {
                enqueueSnackbar(
                  `Date selection restricted: Cannot choose date prior to the last ${pastDateRestrictionDays} days as per company finance policy`,
                  {
                    variant: "warning",
                    autoHideDuration: 10000,
                  }
                );
                dispatch(setSelectedBillDate(""));
              } else {
                dispatch(setSelectedBillDate(formattedDate));
              }
              await setFieldValue(id, formattedDate);
              handleClose();
            }}
          ></Calendar>
        </ListItem>
      </Menu>
    </>
  );
};
