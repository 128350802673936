export enum View {
  USER = "user",
  LEAD = "lead",
  FINANCE = "finance",
}

export enum ClaimReviewAction {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export enum ButtonState {
  ACTIVE = "active",
  LOADING = "loading",
  DISABLED = "disabled",
}

export enum AccessMode {
  VIEW_ONLY = "ViewOnly",
  EDIT_ONLY = "EditOnly",
  EDIT_DELETE = "EditAndDelete",
}

export enum ClaimStatus {
  PENDING_LEAD = "PENDING_LEAD",
  LEAD_REJECTED = "LEAD_REJECTED",
  PENDING_FINANCE = "PENDING_FINANCE",
  FINANCE_REJECTED = "FINANCE_REJECTED",
  APPROVED = "APPROVED",
}

export enum ClaimTabLabels {
  PENDING_CLAIMS = "Pending Claims",
  APPROVED_CLAIMS = "Approved Claims",
  REJECTED_CLAIMS = "Rejected Claims",
}

export enum ReceiptTypes {
  PDF = "PDF",
  IMAGE = "IMAGE",
}

export enum ClaimRangeDropdownValues {
  LATEST_100 = "Latest 100",
  CUSTOM_DATE = "Custom Date",
}

export enum ClaimActivityValues {
  CLAIM_SUBMISSION = "Claim Submission",
  LEAD_REVIEW = "Lead Review",
  FINANCE_REVIEW = "Finance Review",
}

export enum DraftStatus {
  IDLE = "Idle",
  SAVING = "Draft saving ...",
  SAVED = "Draft saved",
  FAILED = "Draft saved error",
}

export type ClaimItem = {
  date: string;
  travelJobNumber: string | null;
  currency: string;
  amount: number;
  reimbursementAmount: number;
  reimbursementCurrency: string;
  currencyConversionRate: number;
  expenseTypeId: number;
  expenseType: string;
  comment: string | null;
  receiptUrl: string | null;
};

export type ClaimItemPayload = Omit<
  ClaimItem,
  "expenseType" | "currencyConversionRate" | "reimbursementAmount" | "reimbursementCurrency"
>;

export type Claim = {
  id: string;
  createdDate: string;
  leadEmails: string[];
  employeeEmail: string;
  currencyCode: string | null;
  statusDetails: {
    status: ClaimStatus;
    leadApprovedDate: string | null;
    financeApprovedDate: string | null;
    leadRejectedDate: string | null;
    financeRejectedDate: string | null;
    leadRejectedReason: string | null;
  };
  totalAmount: number;
  transactions: ClaimItem[];
};

export type ExchangeRate = {
  currencyCode: string;
  exchangeRate: number;
};

export type ExpenseType = {
  id: number;
  type: string;
};

export type CustomFilter = { email: string; claimId: string };

export type AppData = {
  userInfo: {
    workEmail: string;
    employeeThumbnail: string | null;
    managerEmail: string | null;
  };
  draft: {
    transactions: ClaimItem[];
  };
  enableFinanceView: boolean;
  enableLeadView: boolean;
  pastDateRestrictionDays: number | null;
  travels: { jobNumber: string }[];
  currencyCode: string;
};

export type Employee = {
  firstName: string | null;
  lastName: string | null;
  workEmail: string;
  employeeThumbnail: string | null;
};

export type ReceiptDetails = { receiptType: ReceiptTypes | null; generatedUrl: string | null };
