import { TextField, Typography } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { useState } from "react";
import { commentMaxCharacters } from "../../utils/constants";

interface CustomTextAreaProps {
  id: string;
  label: string;
  disabled?: boolean;
}

export const CustomTextArea: React.FC<CustomTextAreaProps> = ({ id, label, disabled }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(id);

  const characterCount = field.value.length;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFieldValue(id, event.target.value);
  };

  return (
    <TextField
      sx={{
        flex: 4,
        ...(disabled && {
          ".MuiOutlinedInput-root": {
            bgcolor: "disable.main",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "disable.dark",
            },
          },
        }),
      }}
      size="small"
      autoComplete="off"
      label={label}
      required
      disabled={disabled}
      {...field}
      multiline
      minRows={2}
      fullWidth
      inputProps={{ maxLength: commentMaxCharacters }}
      onChange={handleChange}
      error={meta && meta.touched && meta.error ? true : false}
      helperText={
        meta && meta.touched && meta.error ? (
          <>
            <Typography
              fontSize={10}
              component={"span"}
              style={{ textAlign: "left", display: "inline-block", width: "50%" }}
            >
              {meta.error}
            </Typography>
            <Typography
              fontSize={10}
              component={"span"}
              style={{ textAlign: "right", display: "inline-block", width: "50%" }}
            >
              {characterCount}/{commentMaxCharacters}
            </Typography>
          </>
        ) : (
          <Typography fontSize={10} component={"span"} style={{ textAlign: "right", display: "block" }}>
            {characterCount}/{commentMaxCharacters}
          </Typography>
        )
      }
    ></TextField>
  );
};
