import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ClaimRangeDropdownValues, ClaimStatus, CustomFilter } from "../../utils/types";

type DateRange = { startDate: string; endDate: string };

interface Filter {
  isClaimRangeVisible: boolean;
  filteringClaimRange: ClaimRangeDropdownValues;
  filteringClaimStatus: ClaimStatus[];
  filteringDateRange: DateRange | null;
  filteringData: CustomFilter;
}

const initialState: Filter = {
  isClaimRangeVisible: true,
  filteringClaimRange: ClaimRangeDropdownValues.LATEST_100,
  filteringClaimStatus: [],
  filteringDateRange: null,
  filteringData: { email: "", claimId: "" },
};

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setIsClaimRangeVisible: (state, action: PayloadAction<boolean>) => {
      state.isClaimRangeVisible = action.payload;
    },
    setFilteringClaimRange: (state, action: PayloadAction<ClaimRangeDropdownValues>) => {
      state.filteringClaimRange = action.payload;
    },
    setFilteringClaimStatus: (state, action: PayloadAction<ClaimStatus[]>) => {
      state.filteringClaimStatus = action.payload;
    },
    setFilteringDateRange: (state, action: PayloadAction<DateRange | null>) => {
      state.filteringDateRange = action.payload;
    },
    setFilteringData: (state, action: PayloadAction<CustomFilter>) => {
      state.filteringData = action.payload;
    },
    resetFilters: () => initialState,
  },
});

export const {
  setIsClaimRangeVisible,
  setFilteringClaimRange,
  setFilteringClaimStatus,
  setFilteringDateRange,
  setFilteringData,
  resetFilters,
} = filterSlice.actions;

export default filterSlice.reducer;
