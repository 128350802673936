import { ExchangeRate, ExpenseType } from "./types";

export const isExchangeRateType = (record: any): record is ExchangeRate => {
  return (
    record != null &&
    typeof record === "object" &&
    record.hasOwnProperty("currencyCode") &&
    record.hasOwnProperty("exchangeRate")
  );
};

export const isExpenseType = (record: any): record is ExpenseType => {
  return record != null && typeof record === "object" && record.hasOwnProperty("id") && record.hasOwnProperty("type");
};
