import { Box, Stack } from "@mui/system";
import { CustomCard } from "../components/card/CustomCard";
import { Slide, TableContainer } from "@mui/material";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useEffect, useRef } from "react";
import { View } from "../utils/types";
import { ClaimTable } from "../components/tables/ClaimTable";
import { CustomCardHeader } from "../components/card/CustomCardHeader";
import { ClaimDetails } from "../components/claim/ClaimDetails";
import { useAppDispatch, useAppSelector } from "../store";
import { useApiService } from "../utils/apiService";
import { fetchClaimsData, resetTableData, setNeedsClaimRefetch } from "../store/slices/tableSlice";
import { removeAllResubmitClaimItems, resetClaimDetails } from "../store/slices/claimDetailsSlice";
import { resetFilters, setFilteringClaimStatus } from "../store/slices/filterSlice";

export const ClaimHistory = () => {
  const filteringData = useAppSelector((state) => state.filter.filteringData);
  const selectedClaimRange = useAppSelector((state) => state.filter.filteringClaimRange);
  const selectedCustomDateRange = useAppSelector((state) => state.filter.filteringDateRange);
  const filteringStatus = useAppSelector((state) => state.filter.filteringClaimStatus);
  const selectedClaim = useAppSelector((state) => state.claimDetails.selectedClaim);
  const claims = useAppSelector((state) => state.table.claims);
  const isClaimDataFetching = useAppSelector((state) => state.table.isClaimDataFetching);
  const needsClaimRefetch = useAppSelector((state) => state.table.needsClaimRefetch);

  const isFetchingFromApi = useRef<boolean>(true);
  const isInitialRender = useRef(true);
  const apiController = useRef(new AbortController());

  const { getApiInstance } = useApiService();
  const dispatch = useAppDispatch();

  const fetchClaims = () => {
    apiController.current = new AbortController();
    isFetchingFromApi.current = true;

    dispatch(
      fetchClaimsData({
        apiInstance: getApiInstance(),
        signal: apiController.current.signal,
        view: View.USER,
        isFetchingFromApi: isFetchingFromApi,
      })
    );
  };

  useEffect(() => {
    if (!isInitialRender.current) {
      fetchClaims();
      return () => {
        if (isFetchingFromApi.current) {
          apiController.current.abort();
          isFetchingFromApi.current = false;
        }
      };
    } else {
      dispatch(removeAllResubmitClaimItems());
      dispatch(resetFilters());
      dispatch(resetTableData());
      dispatch(resetClaimDetails());
      dispatch(setFilteringClaimStatus([]));
      isInitialRender.current = false;
    }
  }, [selectedClaimRange, selectedCustomDateRange, filteringData, filteringStatus]);

  useEffect(() => {
    if (needsClaimRefetch) {
      fetchClaims();
      dispatch(setNeedsClaimRefetch(false));
    }
  }, [needsClaimRefetch]);

  return (
    <CustomCard>
      <div style={{ position: "relative", height: "100%", width: "100%" }}>
        <Stack height={"100%"} width={"100%"} sx={{ opacity: selectedClaim ? 0 : 100 }}>
          <CustomCardHeader title={"Claim History"} view={View.USER} />
          <Box height={"25px"} />
          <TableContainer
            sx={{
              overflowY: !isClaimDataFetching && claims.length > 0 ? "auto" : "hidden",
              marginBottom: "10px",
              paddingRight: "10px",
              height: "100%",
            }}
          >
            <ClaimTable isLoading={isClaimDataFetching} view={View.USER} />
          </TableContainer>
        </Stack>
        <Slide direction="left" in={selectedClaim ? true : false} mountOnEnter unmountOnExit>
          <Stack
            height={"100%"}
            width={"100%"}
            zIndex={2}
            position={"absolute"}
            top={0}
            left={0}
            bottom={0}
            right={0}
            bgcolor={"white"}
          >
            {selectedClaim && !isInitialRender.current && <ClaimDetails view={View.USER} />}
          </Stack>
        </Slide>
      </div>
    </CustomCard>
  );
};
