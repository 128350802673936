import { Autocomplete, Paper, TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { SyntheticEvent, useEffect } from "react";
import { ClaimItem, ExchangeRate, ExpenseType } from "../../utils/types";
import { isExchangeRateType, isExpenseType } from "../../utils/typeGuards";
import { useAppDispatch } from "../../store";
import { setSelectedJobNumber } from "../../store/slices/formSlice";

interface CustomAutoCompleteProps {
  options: any[];
  label: string;
  id: string;
  isExchangeRate?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
}

export const CustomAutoComplete: React.FC<CustomAutoCompleteProps> = ({ options, label, id, disabled, isLoading }) => {
  const { setFieldValue, values } = useFormikContext();
  const [field, meta] = useField(id);
  const dispatch = useAppDispatch();

  const handleChange = async (
    _event: SyntheticEvent<Element, Event>,
    value: string | ExchangeRate | ExpenseType | null
  ) => {
    if (value !== null) {
      if (isExchangeRateType(value)) {
        await setFieldValue(id, value.currencyCode);
        setFieldValue("currencyConversionRate", value.exchangeRate);
      } else if (isExpenseType(value)) {
        await setFieldValue(id, value.type);
        setFieldValue("expenseTypeId", value.id);
      } else if (id === "travelJobNumber") {
        if (value.toLowerCase() === "n/a") {
          await setFieldValue(id, null);
          dispatch(setSelectedJobNumber(null));
        } else {
          await setFieldValue(id, value);
          dispatch(setSelectedJobNumber(value));
        }
        setFieldValue("expenseTypeId", 0);
        setFieldValue("expenseType", "");
      } else {
        setFieldValue(id, value);
      }
    } else {
      await setFieldValue(id, "");
      if (id === "currency") {
        setFieldValue("currencyConversionRate", 0);
      } else if (id === "expenseType") {
        setFieldValue("expenseTypeId", 0);
      } else if (id === "travelJobNumber") {
        dispatch(setSelectedJobNumber(""));
        setFieldValue("expenseTypeId", 0);
        setFieldValue("expenseType", "");
      }
    }
  };

  useEffect(() => {
    if (id === "currency") {
      const conversionRate = (options as ExchangeRate[]).find(
        (option) => option.currencyCode === (values as ClaimItem).currency
      )?.exchangeRate;
      setFieldValue("currencyConversionRate", conversionRate ?? 0);
    }
  }, [options]);

  return (
    <Autocomplete
      id={field.name}
      autoSelect
      blurOnSelect
      disabled={disabled}
      onBlur={field.onBlur}
      options={options}
      autoComplete={false}
      fullWidth={id !== "currency"}
      loading={isLoading}
      value={id === "travelJobNumber" && field.value === null ? "N/A" : field.value === "" ? null : field.value}
      getOptionLabel={(option) => {
        return isExchangeRateType(option) ? option.currencyCode : isExpenseType(option) ? option.type : option;
      }}
      isOptionEqualToValue={(option, value) =>
        isExchangeRateType(option)
          ? option.currencyCode === value
          : isExpenseType(option)
          ? option.type === value
          : option === value
      }
      sx={{
        flex: 2,
        ...(disabled && {
          ".MuiOutlinedInput-root": {
            bgcolor: "disable.main",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "disable.dark",
            },
          },
        }),
      }}
      size="small"
      onChange={handleChange}
      PaperComponent={({ children }) => (
        <Paper style={{ fontSize: 14 }} elevation={6}>
          {children}
        </Paper>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          label={label}
          error={meta && meta.touched && meta.error && !disabled ? true : false}
          helperText={meta && meta.touched && meta.error && !disabled ? meta.error : " "}
        />
      )}
    />
  );
};
