import { useEffect, useState } from "react";
import { useApiService } from "../utils/apiService";
import { getAppData, getEmployees, setLeadName } from "../store/slices/appDataSlice";
import { RouterProvider } from "react-router-dom";
import { router } from "../routes";
import { useAppDispatch, useAppSelector } from "../store";
import { getEmployeeDetails } from "../utils/utils";
import { AppData, Employee } from "../utils/types";
import { PageLoader } from "./PageLoader";

export const AppHandler = () => {
  const accessibleViews = useAppSelector((state) => state.appData.accessibleViews);

  const [isLoading, setIsLoading] = useState(true);

  const { getApiInstance } = useApiService();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getAppDataPromise = dispatch(getAppData({ apiInstance: getApiInstance() }));
    const getEmployeesPromise = dispatch(getEmployees({ apiInstance: getApiInstance() }));

    Promise.all([getAppDataPromise, getEmployeesPromise])
      .then(([appData, employees]) => {
        if (appData.payload && employees.payload) {
          const leadEmail = (appData.payload as AppData).userInfo.managerEmail;
          const leadDetails = leadEmail ? getEmployeeDetails(leadEmail, employees.payload as Employee[]) : null;
          leadDetails && dispatch(setLeadName(leadDetails.firstName + " " + leadDetails.lastName));
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return isLoading ? <PageLoader /> : <RouterProvider router={router(accessibleViews)} />;
};
