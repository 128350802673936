import { Avatar, Box, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getEmployeeDetails, getEmployeeName } from "../../utils/utils";
import { useAppSelector } from "../../store";
import { theme } from "../../theme";

export const UserCard: React.FC<{ email: string }> = ({ email }) => {
  const employees = useAppSelector((state) => state.appData.employees);

  const [employeeThumbnail, setEmployeeThumbnail] = useState<string>();
  const [employeeName, setEmployeeName] = useState<string | null>(null);

  useEffect(() => {
    const employeeDetails = getEmployeeDetails(email, employees);
    setEmployeeThumbnail(employeeDetails?.employeeThumbnail || undefined);
    employeeDetails && setEmployeeName(getEmployeeName(employeeDetails));
  }, []);

  return (
    <Box display={"flex"} whiteSpace={"nowrap"}>
      <Tooltip title={email} arrow>
        <Stack
          direction={"row"}
          alignItems={"center"}
          borderRadius={"20px"}
          border={`1px solid ${theme.palette.border.main}`}
        >
          <Avatar src={employeeThumbnail} sx={{ width: "34px", height: "34px" }} alt={employeeName || email} />
          <Box width={"15px"} />
          <Typography fontSize={"14px"}>{employeeName || email}</Typography>
          <Box width={"15px"} />
        </Stack>
      </Tooltip>
    </Box>
  );
};
