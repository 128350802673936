import { Box, Button, Card, IconButton, Stack, Typography, alpha } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AccessMode, ButtonState, ClaimItem, ReceiptDetails, ReceiptTypes, View } from "../../utils/types";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { ExpenseForm } from "../form/ExpenseForm";
import { useApiService } from "../../utils/apiService";
import { removeClaimItemAndDraft } from "../../store/slices/newClaimSlice";
import { ReceiptViewer } from "../form/ReceiptViewer";
import { AmountPreviewCard } from "../form/AmountPreviewCard";
import { useAppDispatch, useAppSelector } from "../../store";
import { ConfirmationDialog } from "../ConfirmationDialog";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { addReceipt, getReceiptUrl } from "../../store/slices/claimDetailsSlice";
import { theme } from "../../theme";

interface ClaimItemCardProps {
  claimItem: ClaimItem;
  accessMode: AccessMode;
  index: number;
  view: View;
}

export const ClaimItemCard: React.FC<ClaimItemCardProps> = ({ accessMode, claimItem, index, view }) => {
  const confirmationButtonState = useAppSelector((state) => state.common.confirmationButtonState);

  const [isEditingFormOpen, setIsEditingFormOpen] = useState<boolean>(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState<boolean>(false);
  const [isImageViewerOpen, setIsImageViewerOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [generatedReceiptUrl, setGeneratedReceiptUrl] = useState<string | null>("");
  const [receiptType, setReceiptType] = useState<ReceiptTypes | null>(null);

  const { getApiInstance } = useApiService();

  const dispatch = useAppDispatch();

  useEffect(() => {
    setIsLoading(true);
    setReceiptType(null);

    dispatch(
      getReceiptUrl({
        apiInstance: getApiInstance(),
        receiptUrl: claimItem.receiptUrl,
      })
    )
      .then((action) => {
        if (action.meta.requestStatus === "fulfilled") {
          const receiptDetails = action.payload as ReceiptDetails;

          setGeneratedReceiptUrl(receiptDetails.generatedUrl);
          setReceiptType(receiptDetails.receiptType);
          dispatch(addReceipt({ index: index, receipt: receiptDetails }));
        } else {
          setGeneratedReceiptUrl(null);
          setReceiptType(null);
          dispatch(addReceipt({ index: index, receipt: { generatedUrl: null, receiptType: null } }));
        }
      })
      .finally(() => setIsLoading(false));
  }, [claimItem.receiptUrl]);

  const handleDownload = (generatedUrl: string, fileName: string) => {
    const link = document.createElement("a");
    link.href = generatedUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Card sx={{ bgcolor: "secondary.light", marginBottom: "20px" }} variant="outlined">
        <Stack direction={"row"}>
          <Box sx={{ paddingY: "15px", paddingX: "20px" }} width={"100%"}>
            <Stack direction="row" justifyContent="space-between">
              <Typography fontWeight={600}>EXPENSE ITEM {index + 1}</Typography>
              <Stack direction={"row"}>
                {(accessMode === AccessMode.EDIT_ONLY || accessMode === AccessMode.EDIT_DELETE) && (
                  <IconButton
                    size="small"
                    sx={{
                      borderRadius: 1,
                      backgroundColor: "grey.500",
                      "&:hover": {
                        backgroundColor: "grey.600",
                      },
                      paddingY: 0,
                      height: "25px",
                      width: "25px",
                    }}
                    onClick={() => setIsEditingFormOpen(true)}
                  >
                    <EditIcon sx={{ height: "18px", width: "18px", color: "white" }} />
                  </IconButton>
                )}
                {isEditingFormOpen && (
                  <ExpenseForm
                    open={isEditingFormOpen}
                    handleFormClose={() => setIsEditingFormOpen(false)}
                    index={index}
                    enableOnlyExpenseTypeField={view === View.FINANCE}
                  />
                )}
                {accessMode === AccessMode.EDIT_DELETE && (
                  <>
                    <Box width={"15px"} />
                    <IconButton
                      size="small"
                      sx={{
                        borderRadius: 1,
                        backgroundColor: "error.main",
                        "&:hover": {
                          backgroundColor: "error.dark",
                        },
                        paddingY: 0,
                        height: "25px",
                        width: "25px",
                      }}
                      onClick={() => setIsDeleteConfirmationOpen(true)}
                    >
                      <DeleteIcon sx={{ height: "18px", width: "18px", color: "white" }} />
                    </IconButton>
                  </>
                )}
              </Stack>
            </Stack>
            <Box height={"12px"} />
            <Stack direction={"row"} spacing={2}>
              <Stack direction={"column"} flex={1} spacing={1.8}>
                <Stack direction={"row"}>
                  <Stack flex={2} spacing={0.3}>
                    <Typography fontSize={15} fontWeight={600}>
                      Bill Date
                    </Typography>
                    <Typography fontSize={14} noWrap lineHeight={1.2}>
                      {claimItem.date}
                    </Typography>
                  </Stack>
                  <Stack flex={5} spacing={0.3}>
                    <Typography fontSize={15} fontWeight={600}>
                      Job Number
                    </Typography>
                    <Typography fontSize={14} noWrap lineHeight={1.2}>
                      {claimItem.travelJobNumber === null ? "N/A" : claimItem.travelJobNumber}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack spacing={0.3}>
                  <Typography fontSize={15} fontWeight={600}>
                    Expense Type
                  </Typography>
                  <Typography fontSize={14} sx={{ wordBreak: "break-all" }} lineHeight={1.2}>
                    {claimItem.expenseType}
                  </Typography>
                </Stack>
                <Stack spacing={0.3}>
                  <Typography fontSize={15} fontWeight={600}>
                    Comment
                  </Typography>
                  <Typography fontSize={14} sx={{ wordBreak: "break-all" }} lineHeight={1.2}>
                    {claimItem.comment}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction={"column"} alignItems={"end"}>
                <Stack spacing={0.3}>
                  <Typography fontSize={15} fontWeight={600}>
                    Receipt
                  </Typography>
                  {generatedReceiptUrl ? (
                    <>
                      <Stack direction="row" spacing={1.5}>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => setIsImageViewerOpen(true)}
                          sx={{
                            fontSize: "12px",
                            height: "26px",
                            textTransform: "none",
                            color: "grey.500",
                            borderColor: "grey.500",
                            "&:hover": {
                              color: "grey.600",
                              borderColor: "grey.600",
                              bgcolor: alpha(theme.palette.grey[600], 0.05),
                            },
                          }}
                        >
                          View
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            handleDownload(generatedReceiptUrl, claimItem.receiptUrl!);
                          }}
                          sx={{
                            fontSize: "12px",
                            height: "26px",
                            textTransform: "none",
                            color: "grey.500",
                            borderColor: "grey.500",
                            "&:hover": {
                              color: "grey.600",
                              borderColor: "grey.600",
                              bgcolor: alpha(theme.palette.grey[600], 0.05),
                            },
                          }}
                        >
                          Download
                        </Button>
                      </Stack>
                      <ReceiptViewer
                        isImageViewerOpen={isImageViewerOpen}
                        handleImageViewerClose={() => setIsImageViewerOpen(false)}
                        receiptUrl={generatedReceiptUrl}
                        isLoading={isLoading}
                        receiptType={receiptType}
                      />
                    </>
                  ) : (
                    <Typography fontSize={14} color={isLoading ? "inherit" : "error.main"} lineHeight={1.2}>
                      {isLoading ? "Loading..." : "Failed to fetch receipt"}
                    </Typography>
                  )}
                </Stack>
                <Box height={"15px"} />
                <AmountPreviewCard
                  amount={claimItem.amount}
                  conversionRate={claimItem.currencyConversionRate}
                  transactionCurrency={claimItem.currency}
                  reimbursementCurrency={claimItem.reimbursementCurrency}
                  isDetailedCard={true}
                />
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Card>

      <ConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        dialogTitle="Delete Confirmation"
        dialogContent="Are you sure you want to delete the claim item? This action cannot be undone"
        confirmButtonLabel="Confirm"
        isLoading={confirmationButtonState === ButtonState.LOADING}
        handleConfirm={() => {
          dispatch(
            removeClaimItemAndDraft({
              apiInstance: getApiInstance(),
              index: index,
            })
          );
          setIsDeleteConfirmationOpen(false);
        }}
        handleClose={() => setIsDeleteConfirmationOpen(false)}
      />
    </>
  );
};
