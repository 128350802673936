import { BasicUserInfo, useAuthContext } from "@asgardeo/auth-react";
import axios from "axios";
import axiosRetry from "axios-retry";
import { serviceBaseUrl } from "../config";

export const useApiService = () => {
  const { getIDToken, refreshAccessToken } = useAuthContext();

  let refreshPromise: Promise<void | BasicUserInfo> | null = null;

  const instance = axios.create({
    baseURL: serviceBaseUrl,
  });

  instance.interceptors.request.use(async (config) => {
    try {
      const token = await getIDToken();
      config.headers.Authorization = `Bearer ${token}`;
    } catch (error) {
      window.location.reload();
    }
    return config;
  });

  axiosRetry(instance, {
    retries: 1,
    onRetry: async () => {
      if (!refreshPromise) {
        refreshPromise = refreshAccessToken()
          .catch(() => window.location.reload())
          .finally(() => {
            refreshPromise = null;
          });
      }
      await refreshPromise;
    },
    retryCondition: (error) => {
      return error.response?.status === 401;
    },
  });

  const getApiInstance = () => instance;

  return {
    getApiInstance,
  };
};
