import { MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import React from "react";
import { changeStatusLabel } from "../../utils/utils";
import { theme } from "../../theme";

interface FilterBoxProps {
  selectedValue: string;
  handleValueSelection: (event: SelectChangeEvent) => void;
  menuItems: string[];
  filterLabel: string;
  isStatusFilter?: boolean;
}

export const FilterBox: React.FC<FilterBoxProps> = ({
  selectedValue,
  handleValueSelection,
  menuItems,
  filterLabel,
  isStatusFilter,
}) => {
  return (
    <div>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ border: `1px solid ${theme.palette.border.main}` }}
        bgcolor={"cardBackground.main"}
        borderRadius={"3px"}
        height={"100%"}
      >
        <Typography paddingX={"20px"} fontSize={"14px"} fontWeight={500}>
          {filterLabel}
        </Typography>
        <Select
          onChange={handleValueSelection}
          value={selectedValue}
          sx={{
            borderRadius: "0px",
            color: "text.secondary",
            bgcolor: "white",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "transparent",
            },
            "&:hover": {
              "&& fieldset": {
                borderColor: "transparent",
              },
            },
            "& .MuiSelect-select": {
              paddingY: "7px",
            },
            "& .MuiOutlinedInput-input": {
              paddingRight: "38px !important",
            },
          }}
        >
          {isStatusFilter && (
            <MenuItem
              value={"All"}
              sx={{
                fontSize: "14px",
              }}
            >
              All
            </MenuItem>
          )}
          {menuItems.map((menuItem, index) => (
            <MenuItem
              value={menuItem}
              key={index}
              sx={{
                fontSize: "14px",
              }}
            >
              {isStatusFilter ? changeStatusLabel(menuItem) : menuItem}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </div>
  );
};
