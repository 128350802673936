import { Card } from "@mui/material";
import { sideBarWidth } from "../../utils/constants";

export const CustomCard: React.FC<{ children: React.ReactNode }> = (props) => {
  return (
    <Card
      variant="outlined"
      sx={{
        margin: "15px",
        marginLeft: `${sideBarWidth + 20}px`,
        bgcolor: "white",
        paddingX: "20px",
        paddingTop: "20px",
        paddingBottom: "5px",
        borderRadius: "12px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {props.children}
    </Card>
  );
};
