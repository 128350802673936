import { Skeleton, TableCell, TableRow } from "@mui/material";

export const SkeletonTable = () => {
  return (
    <>
      {Array.from({ length: 50 }, (_, rowIndex) => (
        <TableRow key={rowIndex}>
          {Array.from({ length: 5 }, (_, cellIndex) => (
            <TableCell key={cellIndex}>
              <Skeleton></Skeleton>
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};
