import { Divider, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import LogoutIcon from "@mui/icons-material/Logout";
import { theme } from "../../theme";
import { useAuthContext } from "@asgardeo/auth-react";
import { supportUrl } from "../../config";

interface SettingsMenuProps {
  anchorEl: HTMLElement | null;
  handleMenuClose: () => void;
}

export const SettingsMenu: React.FC<SettingsMenuProps> = (props) => {
  const { signOut } = useAuthContext();
  const open = Boolean(props.anchorEl);

  const handleGetHelpBtnClick = () => {
    window.open(supportUrl);
    props.handleMenuClose();
  };

  const handleLogoutBtnClick = () => {
    props.handleMenuClose();
    signOut();
  };

  return (
    <Menu
      anchorEl={props.anchorEl}
      open={open}
      onClose={props.handleMenuClose}
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: "white",
        },
      }}
    >
      {/* This should be uncommented when the User Guide is completed */}
      {/* <MenuItem sx={{ paddingRight: 6 }}>
        <ListItemIcon>
          <DescriptionIcon fontSize="small" sx={{ color: theme.palette.text.primary }} />
        </ListItemIcon>
        <ListItemText>User Guide</ListItemText>
      </MenuItem> */}
      <MenuItem onClick={handleGetHelpBtnClick} sx={{ paddingRight: 6 }}>
        <ListItemIcon>
          <HelpIcon fontSize="small" sx={{ color: theme.palette.text.primary }} />
        </ListItemIcon>
        <ListItemText>Get Help</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleLogoutBtnClick}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" sx={{ color: theme.palette.text.primary }} />
        </ListItemIcon>
        <ListItemText>Logout</ListItemText>
      </MenuItem>
    </Menu>
  );
};
