import { theme } from "./theme";
import { ThemeProvider } from "@emotion/react";
import { SnackbarProvider } from "notistack";
import { useAuthContext } from "@asgardeo/auth-react";
import { useEffect, useState } from "react";
import { AppHandler } from "./components/AppHandler";
import { Login } from "./pages/Login";
import { PageLoader } from "./components/PageLoader";

export default function App() {
  const { state, refreshAccessToken } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (state.isAuthenticated && !state.isLoading) {
      refreshAccessToken().then(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
      <ThemeProvider theme={theme}>
        {state.isLoading || isLoading ? <PageLoader /> : state.isAuthenticated ? <AppHandler /> : <Login />}
      </ThemeProvider>
    </SnackbarProvider>
  );
}
