import React, { useEffect, useRef } from "react";
import { CustomCard } from "../components/card/CustomCard";
import { CustomCardHeader } from "../components/card/CustomCardHeader";
import { ClaimStatus, ClaimTabLabels, View } from "../utils/types";
import { Box, Slide, Stack, Tab, TableContainer, Tabs, Typography } from "@mui/material";
import PendingIcon from "@mui/icons-material/PendingActions";
import ApprovedIcon from "@mui/icons-material/InventoryOutlined";
import RejectedIcon from "@mui/icons-material/CancelPresentationOutlined";
import { ClaimTable } from "../components/tables/ClaimTable";
import { ClaimDetails } from "../components/claim/ClaimDetails";
import { useApiService } from "../utils/apiService";
import { useAppDispatch, useAppSelector } from "../store";
import { setIsClaimRangeVisible, setFilteringClaimStatus, resetFilters } from "../store/slices/filterSlice";
import { fetchClaimsData, resetTableData } from "../store/slices/tableSlice";
import { removeAllResubmitClaimItems, resetClaimDetails } from "../store/slices/claimDetailsSlice";

export const Approvals: React.FC<{ view: View }> = ({ view }) => {
  const filteringData = useAppSelector((state) => state.filter.filteringData);
  const selectedClaimRange = useAppSelector((state) => state.filter.filteringClaimRange);
  const selectedCustomDateRange = useAppSelector((state) => state.filter.filteringDateRange);
  const filteringStatus = useAppSelector((state) => state.filter.filteringClaimStatus);
  const selectedClaim = useAppSelector((state) => state.claimDetails.selectedClaim);
  const isClaimDataFetching = useAppSelector((state) => state.table.isClaimDataFetching);
  const claims = useAppSelector((state) => state.table.claims);

  const [selectedTab, setSelectedTab] = React.useState<ClaimTabLabels>(ClaimTabLabels.PENDING_CLAIMS);
  const isFetchingFromApi = useRef<boolean>(true);
  const isInitialRender = useRef(true);
  const apiController = useRef(new AbortController());

  const { getApiInstance } = useApiService();
  const dispatch = useAppDispatch();

  const handleChange = (_event: React.SyntheticEvent, tab: ClaimTabLabels) => {
    dispatch(resetFilters());
    setSelectedTab(tab);

    switch (tab) {
      case ClaimTabLabels.PENDING_CLAIMS:
        dispatch(
          setFilteringClaimStatus(view === View.LEAD ? [ClaimStatus.PENDING_LEAD] : [ClaimStatus.PENDING_FINANCE])
        );
        dispatch(setIsClaimRangeVisible(false));
        break;

      case ClaimTabLabels.APPROVED_CLAIMS:
        dispatch(
          setFilteringClaimStatus(
            view === View.LEAD
              ? [ClaimStatus.PENDING_FINANCE, ClaimStatus.APPROVED, ClaimStatus.FINANCE_REJECTED]
              : [ClaimStatus.APPROVED]
          )
        );
        dispatch(setIsClaimRangeVisible(true));
        break;

      default:
        dispatch(
          setFilteringClaimStatus(view === View.LEAD ? [ClaimStatus.LEAD_REJECTED] : [ClaimStatus.FINANCE_REJECTED])
        );
        dispatch(setIsClaimRangeVisible(true));
        break;
    }
  };

  useEffect(() => {
    if (!isInitialRender.current) {
      isFetchingFromApi.current = true;
      apiController.current = new AbortController();
      dispatch(
        fetchClaimsData({
          apiInstance: getApiInstance(),
          signal: apiController.current.signal,
          view: view,
          isFetchingFromApi: isFetchingFromApi,
        })
      );
      return () => {
        if (isFetchingFromApi.current) {
          apiController.current.abort();
          isFetchingFromApi.current = false;
        }
      };
    } else {
      dispatch(removeAllResubmitClaimItems());
      dispatch(resetFilters());
      dispatch(resetTableData());
      dispatch(resetClaimDetails());
      dispatch(
        setFilteringClaimStatus(view === View.LEAD ? [ClaimStatus.PENDING_LEAD] : [ClaimStatus.PENDING_FINANCE])
      );
      dispatch(setIsClaimRangeVisible(false));
      isInitialRender.current = false;
    }
  }, [selectedClaimRange, selectedCustomDateRange, filteringData, filteringStatus]);

  return (
    <CustomCard>
      <div style={{ position: "relative", height: "100%", width: "100%" }}>
        <Stack height={"100%"} width={"100%"} sx={{ opacity: selectedClaim ? 0 : 100 }}>
          <CustomCardHeader title={view === View.LEAD ? "Lead Approvals" : "Finance Approvals"} view={view} />
          <Box height={"5px"} />
          <Tabs value={selectedTab} onChange={handleChange}>
            {[
              {
                icon: <PendingIcon sx={{ fontSize: "20px" }} />,
                value: ClaimTabLabels.PENDING_CLAIMS,
                label: "Pending Claims",
              },
              {
                icon: <ApprovedIcon sx={{ fontSize: "20px" }} />,
                value: ClaimTabLabels.APPROVED_CLAIMS,
                label: "Approved Claims",
              },
              {
                icon: <RejectedIcon sx={{ fontSize: "20px" }} />,
                value: ClaimTabLabels.REJECTED_CLAIMS,
                label: "Rejected Claims",
              },
            ].map((tab) => (
              <Tab
                key={tab.value}
                icon={tab.icon}
                value={tab.value}
                label={<Typography fontSize={"14px"}>{tab.label}</Typography>}
                iconPosition="start"
                disableRipple={true}
                sx={{ height: "50px", minHeight: 0, textTransform: "none", paddingBottom: 0 }}
              />
            ))}
          </Tabs>
          <Box height={"10px"} />
          {[
            { tabLabel: ClaimTabLabels.PENDING_CLAIMS },
            { tabLabel: ClaimTabLabels.APPROVED_CLAIMS },
            { tabLabel: ClaimTabLabels.REJECTED_CLAIMS },
          ].map(
            (claimTab) =>
              selectedTab === claimTab.tabLabel && (
                <TableContainer
                  key={claimTab.tabLabel}
                  sx={{
                    overflowY: !isClaimDataFetching && claims.length > 0 ? "auto" : "hidden",
                    marginY: "10px",
                    paddingRight: "10px",
                    height: "100%",
                  }}
                >
                  <ClaimTable
                    isLoading={isClaimDataFetching}
                    isPendingClaims={selectedTab === ClaimTabLabels.PENDING_CLAIMS}
                    view={view}
                  />
                </TableContainer>
              )
          )}
        </Stack>
        <Slide direction="left" in={selectedClaim ? true : false} mountOnEnter unmountOnExit>
          <Stack
            height={"100%"}
            width={"100%"}
            zIndex={2}
            position={"absolute"}
            top={0}
            left={0}
            bottom={0}
            right={0}
            bgcolor={"white"}
          >
            {selectedClaim && !isInitialRender.current && (
              <ClaimDetails view={view} isPendingClaims={selectedTab === ClaimTabLabels.PENDING_CLAIMS} />
            )}
          </Stack>
        </Slide>
      </div>
    </CustomCard>
  );
};
