import { Divider, Typography } from "@mui/material";
import { NumericFormat } from "react-number-format";

interface AmountFooterProps {
  reimbursementAmount: number;
  reimbursementCurrency: string | null;
}

export const AmountFooter: React.FC<AmountFooterProps> = ({ reimbursementAmount, reimbursementCurrency }) => {
  return (
    <>
      <Divider />
      <Typography
        fontSize={"14px"}
        fontWeight={500}
        textAlign={"right"}
        paddingX={"20px"}
        paddingTop={"8px"}
        paddingBottom={"5px"}
      >
        Total Amount:
        <span style={{ fontSize: "19px", fontWeight: 600, marginLeft: "10px", color: "text.secondary" }}>
          <NumericFormat
            value={reimbursementAmount}
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator
            displayType="text"
          />
          {" " + reimbursementCurrency}
        </span>
      </Typography>
    </>
  );
};
