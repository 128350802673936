import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import LoaderImg from "../assets/images/loading.svg";
import { appName } from "../config";

export const PageLoader = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Stack spacing={3}>
        <Stack spacing={2}>
          <img src={LoaderImg} height={220} />
          <Typography fontSize={17} fontWeight={500}>
            {`WSO2 ${appName}`}
          </Typography>
        </Stack>
        <Box px={4.5}>
          <LinearProgress />
        </Box>
      </Stack>
    </Box>
  );
};
