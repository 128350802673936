import { Box, Button, Stack, Typography } from "@mui/material";
import { CustomCard } from "../components/card/CustomCard";
import { ExpenseForm } from "../components/form/ExpenseForm";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch, useAppSelector } from "../store";
import { ClaimItemCard } from "../components/claim/ClaimItemCard";
import { useApiService } from "../utils/apiService";
import { AccessMode, ButtonState, ClaimItem, DraftStatus, View } from "../utils/types";
import { addClaimItem, submitClaim } from "../store/slices/newClaimSlice";
import DraftRestoreImage from "../assets/images/draft-restore.svg";
import DraftRestoreIcon from "@mui/icons-material/RestoreOutlined";
import DraftSavedIcon from "@mui/icons-material/CloudDoneOutlined";
import DraftSavingIcon from "@mui/icons-material/LoopOutlined";
import DraftErrorIcon from "@mui/icons-material/CloudOffOutlined";
import { ConfirmationDialog } from "../components/ConfirmationDialog";
import { enqueueSnackbar } from "notistack";
import { AmountFooter } from "../components/claim/AmountFooter";

export const NewClaim = () => {
  const claimItems = useAppSelector((state) => state.newClaim.claimItems);
  const totalReimbursementAmount = useAppSelector((state) => state.newClaim.totalAmount);
  const reimbursementCurrency = useAppSelector((state) => state.appData.reimbursementCurrency);
  const draftClaimItems = useAppSelector((state) => state.newClaim.draftClaimItems);
  const draftStatus = useAppSelector((state) => state.newClaim.draftStatus);
  const confirmationButtonState = useAppSelector((state) => state.common.confirmationButtonState);
  const leadName = useAppSelector((state) => state.appData.leadName);
  const leadEmail = useAppSelector((state) => state.appData.leadEmail);

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [openSubmitConfirmation, setOpenSubmitConfirmation] = useState(false);
  const [openDraftDeletionConfirmation, setOpenDraftDeletionConfirmation] = useState(false);

  const { getApiInstance } = useApiService();
  const dispatch = useAppDispatch();

  const handleDraftRestore = () => {
    draftClaimItems.map((draft: ClaimItem) => dispatch(addClaimItem(draft)));
    enqueueSnackbar("Draft restored successfully", {
      variant: "success",
    });
  };

  const getDraftStatusIcon = () => {
    switch (draftStatus) {
      case DraftStatus.SAVING:
        return <DraftSavingIcon sx={{ height: "17px" }} />;
      case DraftStatus.SAVED:
        return <DraftSavedIcon sx={{ height: "17px" }} />;
      case DraftStatus.FAILED:
        return <DraftErrorIcon sx={{ height: "17px", color: "white" }} />;
    }
  };

  return (
    <>
      <CustomCard>
        <>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack direction={"row"} alignItems={"center"} spacing={3}>
              <Typography fontSize={20} fontWeight={600}>
                New Claim
              </Typography>
              {claimItems.length > 0 && draftStatus !== DraftStatus.IDLE && (
                <Stack
                  direction={"row"}
                  padding={"5px"}
                  borderRadius={2}
                  bgcolor={draftStatus != DraftStatus.FAILED ? "grey.200" : "error.main"}
                  alignItems={"center"}
                >
                  <Box width={"6px"} />
                  {getDraftStatusIcon()}
                  <Box width={"8px"} />
                  <Typography
                    fontSize={13}
                    fontWeight={500}
                    color={draftStatus != DraftStatus.FAILED ? "inherit" : "white"}
                  >
                    {draftStatus}
                  </Typography>
                  <Box width={"6px"} />
                </Stack>
              )}
            </Stack>
            {claimItems.length > 0 && (
              <Stack direction={"row"}>
                <Button color="primary" onClick={() => setIsFormOpen(true)} startIcon={<AddIcon />} variant="outlined">
                  Add Expense
                </Button>
                <Box width={"20px"} />
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ width: "120px" }}
                  onClick={() => setOpenSubmitConfirmation(true)}
                >
                  Submit
                </Button>
              </Stack>
            )}
          </Stack>
          {claimItems.length > 0 ? (
            <>
              <Box sx={{ overflowY: "auto", marginY: "15px", paddingX: "3px", paddingY: "5px", height: "100%" }}>
                <Stack>
                  {claimItems.map((claimItem, index) => (
                    <ClaimItemCard
                      claimItem={claimItem}
                      index={index}
                      key={index}
                      accessMode={AccessMode.EDIT_DELETE}
                      view={View.USER}
                    />
                  ))}
                </Stack>
              </Box>
              <AmountFooter
                reimbursementAmount={totalReimbursementAmount}
                reimbursementCurrency={reimbursementCurrency}
              />
            </>
          ) : (
            <Stack alignItems="center" justifyContent="center" height={"100%"}>
              <img src={DraftRestoreImage} alt="Draft Restore" height={"200px"} />
              <Box height={"40px"} />
              <Typography align="center">
                It looks like you haven't added any expenses yet <br />
                Let's add your expense claim
              </Typography>
              <Stack direction={"row"} alignItems="center" marginTop={"25px"}>
                <Box>
                  <Button
                    variant={"outlined"}
                    onClick={() => {
                      if (draftClaimItems.length > 0) {
                        setOpenDraftDeletionConfirmation(true);
                      } else {
                        setIsFormOpen(true);
                      }
                    }}
                    color="primary"
                    startIcon={<AddIcon />}
                  >
                    Add Expense
                  </Button>
                </Box>
                {draftClaimItems.length > 0 && (
                  <>
                    <Box width={"25px"} />
                    <Typography fontWeight={600}>OR</Typography>
                    <Box width={"25px"} />
                    <Box>
                      <Button
                        variant={"outlined"}
                        onClick={handleDraftRestore}
                        color="success"
                        startIcon={<DraftRestoreIcon />}
                      >
                        Restore Draft
                      </Button>
                    </Box>
                  </>
                )}
              </Stack>
            </Stack>
          )}
        </>
      </CustomCard>

      {isFormOpen && <ExpenseForm open={isFormOpen} handleFormClose={() => setIsFormOpen(false)} />}

      <ConfirmationDialog
        isOpen={openSubmitConfirmation}
        isLoading={confirmationButtonState === ButtonState.LOADING}
        dialogTitle="Claim Submission Confirmation"
        dialogContent={
          <Typography fontSize={"15px"} lineHeight={1.6}>
            Are you sure you want to submit this claim? Once submitted, it will be sent to your lead
            {(leadName || (leadEmail && leadEmail.length > 0)) && (
              <span style={{ fontWeight: "500" }}>{` (${leadName || leadEmail})`}</span>
            )}{" "}
            for review.
          </Typography>
        }
        confirmButtonLabel="Submit"
        handleConfirm={() => {
          dispatch(
            submitClaim({
              apiInstance: getApiInstance(),
            })
          ).then((action) => {
            if (action.meta.requestStatus === "fulfilled") {
              setOpenSubmitConfirmation(false);
            }
          });
        }}
        handleClose={() => setOpenSubmitConfirmation(false)}
      />
      <ConfirmationDialog
        isOpen={openDraftDeletionConfirmation}
        isLoading={confirmationButtonState === ButtonState.LOADING}
        dialogTitle="Draft Deletion Warning"
        dialogContent="Adding a new claim will delete your draft. Are you sure you want to proceed?"
        confirmButtonLabel="Confirm"
        handleConfirm={() => {
          setOpenDraftDeletionConfirmation(false);
          setIsFormOpen(true);
        }}
        handleClose={() => setOpenDraftDeletionConfirmation(false)}
      />
    </>
  );
};
