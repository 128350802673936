import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { CustomCurrencyField } from "../input-fields/CustomCurrencyField";
import { CustomAutoComplete } from "../input-fields/CustomAutoComplete";
import { AmountPreviewCard } from "./AmountPreviewCard";
import { CustomTextArea } from "../input-fields/CustomTextArea";
import { CustomDatePicker } from "../input-fields/CustomDatePicker";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import * as Yup from "yup";
import { useEffect } from "react";
import { ClaimItem, ClaimItemPayload } from "../../utils/types";
import { FileUploadArea } from "../input-fields/FileUploadArea";
import { useAppDispatch, useAppSelector } from "../../store";
import { useApiService } from "../../utils/apiService";
import { addClaimItem, editClaimItem, saveClaimDraft } from "../../store/slices/newClaimSlice";
import { PopupTransition } from "../PopupTransition";
import { LoadingButton } from "@mui/lab";
import dayjs from "dayjs";
import { editResubmitClaimItem } from "../../store/slices/claimDetailsSlice";
import {
  fetchExchangeRates,
  fetchExpenseTypes,
  resetForm,
  setExchangeRates,
  setExpenseTypes,
  setSelectedBillDate,
  setSelectedJobNumber,
} from "../../store/slices/formSlice";
import { convertToClaimItemPayload } from "../../utils/utils";

interface ExpenseFormProps {
  open: boolean;
  handleFormClose: () => void;
  index?: number;
  enableOnlyExpenseTypeField?: boolean;
}

export const ExpenseForm: React.FC<ExpenseFormProps> = ({
  open,
  handleFormClose,
  index,
  enableOnlyExpenseTypeField,
}) => {
  const pastDateRestrictionDays = useAppSelector((state) => state.appData.pastDateRestrictionDays);
  const jobNumbers = useAppSelector((state) => state.appData.jobNumbers);
  const reimbursementCurrency = useAppSelector((state) => state.appData.reimbursementCurrency);
  const claimItems = useAppSelector((state) => state.newClaim.claimItems);
  const resubmitClaimItems = useAppSelector((state) => state.claimDetails.resubmitClaimItems);
  const selectedClaim = useAppSelector((state) => state.claimDetails.selectedClaim);
  const selectedBillDate = useAppSelector((state) => state.form.selectedBillDate);
  const selectedJobNumber = useAppSelector((state) => state.form.selectedJobNumber);
  const exchangeRates = useAppSelector((state) => state.form.exchangeRates);
  const expenseTypes = useAppSelector((state) => state.form.expenseTypes);
  const isRatesFetching = useAppSelector((state) => state.form.isRatesFetching);
  const isExpenseTypesFetching = useAppSelector((state) => state.form.isExpenseTypesFetching);
  const isReceiptAttached = useAppSelector((state) => state.form.isReceiptAttached);
  const temporaryReceiptUrl = useAppSelector((state) => state.form.temporaryReceiptUrl);

  const isEditForm: boolean = index !== undefined;
  const isResubmit: boolean = resubmitClaimItems.length > 0;

  const dispatch = useAppDispatch();

  const { getApiInstance } = useApiService();

  const initialFormData: ClaimItem = isEditForm
    ? isResubmit
      ? resubmitClaimItems[index!]
      : claimItems[index!]
    : {
        date: "",
        travelJobNumber: "",
        currency: "",
        amount: 0,
        reimbursementAmount: 0,
        reimbursementCurrency,
        currencyConversionRate: 0,
        expenseTypeId: 0,
        expenseType: "",
        comment: "",
        receiptUrl: "",
      };

  const areAllFieldsFilled = (claimItem: ClaimItem) =>
    Object.values(claimItem).every((value) => value !== "" && value !== 0);

  useEffect(() => {
    if (isEditForm) {
      dispatch(setSelectedJobNumber(initialFormData.travelJobNumber));
      dispatch(setSelectedBillDate(initialFormData.date));
    }
  }, []);

  useEffect(() => {
    dispatch(setExchangeRates([]));
    if (selectedBillDate.length > 0) {
      dispatch(
        fetchExchangeRates({
          apiInstance: getApiInstance(),
          reimbursementCurrency: initialFormData.reimbursementCurrency,
        })
      );
    }
  }, [selectedBillDate]);

  useEffect(() => {
    dispatch(setExpenseTypes([]));
    if (selectedJobNumber != null) {
      if (selectedJobNumber.length > 0) {
        dispatch(fetchExpenseTypes({ apiInstance: getApiInstance(), selectedJobNumber: selectedJobNumber }));
      }
    } else {
      dispatch(fetchExpenseTypes({ apiInstance: getApiInstance() }));
    }
  }, [selectedJobNumber]);

  const formValidation = Yup.object().shape({
    date: Yup.string()
      .required("Required")
      .test("date-validation", `Date within last ${pastDateRestrictionDays} days required`, function (value) {
        if (pastDateRestrictionDays) {
          if (isResubmit) {
            return dayjs(value).isAfter(dayjs(selectedClaim?.createdDate).subtract(pastDateRestrictionDays, "days"));
          }
          return dayjs(value).isAfter(dayjs().subtract(pastDateRestrictionDays, "days"));
        }
        return true;
      }),
    travelJobNumber: Yup.string().required("Required").nullable(),
    currency: Yup.string().required("Required"),
    amount: Yup.number().required("Required"),
    expenseType: Yup.string().required("Required"),
    comment: Yup.string().required("Required"),
    receiptUrl: Yup.string().required(),
  });

  return (
    <Dialog
      open={open}
      TransitionComponent={PopupTransition}
      PaperProps={{
        style: {
          width: "550px",
          backgroundColor: "secondary.main",
          backgroundImage: "none",
          borderRadius: 10,
          padding: 8,
        },
      }}
    >
      <Formik
        initialValues={initialFormData}
        validationSchema={formValidation}
        onSubmit={(values: ClaimItem, action) => {
          if (isEditForm) {
            if (isResubmit) {
              dispatch(editResubmitClaimItem({ item: values, index: index! }));
              action.setSubmitting(false);
              dispatch(resetForm());
              handleFormClose();
            } else {
              const changedClaimItems = Array.from(claimItems);
              changedClaimItems.splice(index!, 1, values);
              const modifiedClaimItems: ClaimItemPayload[] = convertToClaimItemPayload(changedClaimItems);

              dispatch(saveClaimDraft({ apiInstance: getApiInstance(), claimItems: modifiedClaimItems }));
              dispatch(editClaimItem({ item: values, index: index! }));
              dispatch(resetForm());
              action.setSubmitting(false);
              handleFormClose();
            }
          } else {
            const modifiedClaimItems: ClaimItemPayload[] = convertToClaimItemPayload([...claimItems, values]);

            dispatch(saveClaimDraft({ apiInstance: getApiInstance(), claimItems: modifiedClaimItems }));
            dispatch(addClaimItem(values));
            dispatch(resetForm());
            action.setSubmitting(false);
            handleFormClose();
          }
        }}
      >
        {({ handleSubmit, values, isSubmitting, setFieldValue }) => (
          <>
            <DialogTitle sx={{ fontWeight: "600", paddingTop: "10px" }}>
              {isEditForm ? "Update Expense" : "Add Expense"}
            </DialogTitle>
            <DialogContent sx={{ paddingX: 0, paddingBottom: 0 }}>
              <Box height={6} />
              <Stack paddingInline={"24px"} spacing={"6px"}>
                <CustomDatePicker
                  id="date"
                  label={"Bill Date"}
                  disabled={enableOnlyExpenseTypeField}
                  submittedDate={isResubmit ? selectedClaim?.createdDate : undefined}
                />
                <Card
                  variant="outlined"
                  sx={{
                    backgroundColor: "cardBackground.main",
                    paddingX: "15px",
                    paddingY: "12px",
                  }}
                >
                  <Typography fontSize={15} fontWeight={500}>
                    Transaction Details
                  </Typography>
                  <div style={{ position: "relative" }}>
                    <Box height={"20px"} />
                    <div style={{ opacity: isRatesFetching ? 0 : 1 }}>
                      <Stack direction={"row"}>
                        <CustomAutoComplete
                          label="Currency"
                          isLoading={isRatesFetching}
                          options={exchangeRates}
                          id="currency"
                          disabled={selectedBillDate.length === 0 || enableOnlyExpenseTypeField}
                          isExchangeRate
                        />
                        <Box width={"30px"} />
                        <CustomCurrencyField
                          id="amount"
                          label="Transaction Amount"
                          disabled={selectedBillDate.length === 0 || enableOnlyExpenseTypeField}
                        />
                      </Stack>
                      <Box height={6} />
                      <AmountPreviewCard
                        conversionRate={values.currencyConversionRate}
                        amount={values.amount}
                        transactionCurrency={values.currency}
                        reimbursementCurrency={values.reimbursementCurrency}
                        setFieldValue={setFieldValue}
                      />
                    </div>
                    {isRatesFetching && (
                      <Box
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Stack alignItems={"center"}>
                          <Typography fontSize={14}>Fetching Exchange Rates</Typography>
                          <Box height={12} />
                          <CircularProgress size={20} />
                        </Stack>
                      </Box>
                    )}
                  </div>
                </Card>
              </Stack>
              <Box height={"25px"} />
              <Stack direction={"row"} paddingLeft={"24px"}>
                <CustomAutoComplete
                  label="Job Number"
                  options={jobNumbers}
                  id="travelJobNumber"
                  disabled={enableOnlyExpenseTypeField}
                />
                <Tooltip title={'Select "N/A" if no job number is available'} placement="top-start" arrow>
                  <InfoIcon sx={{ height: 14 }} />
                </Tooltip>
              </Stack>
              <Box height={6} />
              <Stack paddingInline={"24px"} spacing={"6px"}>
                <CustomAutoComplete
                  label="Expense Type"
                  isLoading={isExpenseTypesFetching}
                  options={expenseTypes}
                  id="expenseType"
                  disabled={values.travelJobNumber?.length === 0}
                />
                <CustomTextArea id="comment" label="Comment" disabled={enableOnlyExpenseTypeField} />
                {isEditForm ? (
                  <FileUploadArea isEdit={true} disabled={enableOnlyExpenseTypeField} />
                ) : (
                  <FileUploadArea />
                )}
              </Stack>
            </DialogContent>
            <DialogActions sx={{ paddingTop: 5 }}>
              <Button
                onClick={() => {
                  dispatch(resetForm());
                  handleFormClose();
                }}
                sx={{ color: "text.secondary", paddingX: "20px", paddingY: "5px" }}
                size="small"
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                loading={isSubmitting}
                size="small"
                sx={{ paddingX: "30px", paddingY: "5px" }}
                disabled={isEditForm ? !(areAllFieldsFilled(values) && isReceiptAttached) : !areAllFieldsFilled(values)}
                onClick={async () => {
                  if (temporaryReceiptUrl.length > 0) {
                    await setFieldValue("receiptUrl", temporaryReceiptUrl);
                  }
                  handleSubmit();
                }}
              >
                {isEditForm ? "Update" : "Add"}
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
