import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { Claim, ReceiptDetails, ReceiptTypes } from "../../utils/types";
import ReportHeader from "../../assets/images/report-header.png";
import { pdfjs } from "react-pdf";
import { theme } from "../../theme";
import { alpha } from "@mui/material";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { numericFormatter } from "react-number-format";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const styles = StyleSheet.create({
  body: {
    paddingTop: 25,
    paddingHorizontal: 35,
  },
  imgBody: {
    paddingTop: 15,
    paddingHorizontal: 35,
  },
  card: {
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    borderRadius: 6,
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    padding: 10,
    marginBottom: 15,
  },
  cardRow: {
    flexDirection: "row",
  },
  cardSpace: {
    paddingTop: 8,
  },
  titleColumn: {
    flex: 1,
    font: "Helvetica-Bold",
    fontWeight: "bold",
    fontSize: 10,
  },
  column: {
    flex: 2,
    fontSize: 10,
  },
  table: {
    width: "100%",
    marginTop: 20,
  },
  tableRow: {
    flexDirection: "row",
  },
  leftTableCell: {
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderColor: theme.palette.border.main,
    flex: 1,
    padding: 8,
    fontSize: 10,
    backgroundColor: theme.palette.cardBackground.main,
  },
  rightTableCell: {
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderColor: theme.palette.border.main,
    flex: 2.2,
    padding: 8,
    fontSize: 10,
    flexDirection: "row",
  },
  header: {
    width: "100%",
    textAlign: "center",
    padding: 8,
    backgroundColor: theme.palette.grey[200],
    borderWidth: 1,
    borderColor: theme.palette.border.main,
    fontSize: 13,
  },
  receiptHeader: {
    width: "100%",
    textAlign: "center",
    paddingBottom: 10,
    fontSize: 15,
  },
  page: {
    paddingBottom: 10,
  },
  lineHeightAdjustedText: {
    lineHeight: 1.5,
  },
});

type Receipt = {
  pages: string[];
};

interface ReportTemplateProps {
  claim: Claim;
  receiptsDetails: ReceiptDetails[];
  setIsConvertingReceipts: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ReportTemplate: React.FC<ReportTemplateProps> = ({ claim, receiptsDetails, setIsConvertingReceipts }) => {
  const [receipts, setReceipts] = useState<Receipt[]>([]);

  useEffect(() => {
    setIsConvertingReceipts(true);
    const convertPDFToImages = async (pdfUrl: string) => {
      try {
        const pdf = await pdfjs.getDocument(pdfUrl).promise;
        const images = [];

        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const scale = 1.5;
          const viewport = page.getViewport({ scale });
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");

          if (context) {
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            await page.render({ canvasContext: context, viewport }).promise;
            const imageUrl = canvas.toDataURL("image/png");
            images.push(imageUrl);
          }
        }
        return images;
      } catch (error) {
        return [];
      }
    };

    const processReceipts = async () => {
      for (let index = 0; index < receiptsDetails.length; index++) {
        const { generatedUrl, receiptType } = receiptsDetails[index];

        if (generatedUrl) {
          const receiptUrl = generatedUrl;

          if (receiptType === ReceiptTypes.PDF) {
            const images = await convertPDFToImages(receiptUrl);
            setReceipts((prev) => {
              prev[index] = { pages: images };
              return [...prev];
            });
          } else {
            setReceipts((prev) => {
              prev[index] = { pages: [receiptUrl] };
              return [...prev];
            });
          }
        }
      }
    };

    processReceipts().then(() => {
      setIsConvertingReceipts(false);
    });
  }, []);

  return (
    <Document>
      <Page style={styles.page}>
        <Image src={ReportHeader}></Image>
        <View style={styles.body}>
          <View style={styles.card}>
            {[
              ["Netsuite Ref", claim.id],
              [
                "Total Reimbursement Amount",
                `${numericFormatter(claim.totalAmount.toString(), { decimalScale: 2, thousandSeparator: true })} ${
                  claim.currencyCode
                }`,
              ],
              ["Submitted Date", `${claim.createdDate.slice(0, -2)} (GMT 00:00)`],
            ].map(([title, value], index) => (
              <View key={index} style={index === 0 ? styles.cardRow : [styles.cardRow, styles.cardSpace]}>
                <View style={styles.titleColumn}>
                  <Text>{title}</Text>
                </View>
                <View style={styles.column}>
                  <Text>{value}</Text>
                </View>
              </View>
            ))}
          </View>

          {claim.transactions.map((transaction, index) => (
            <View key={index} style={styles.table} wrap={false}>
              <View style={styles.tableRow}>
                <View style={styles.header}>
                  <Text>Claim Item {index + 1}</Text>
                </View>
              </View>
              {[
                ["Bill Date", transaction.date],
                ["Job Number", transaction.travelJobNumber || "N/A"],
                ["Expense Type", transaction.expenseType],
                [
                  "Bill Amount",
                  `${numericFormatter(transaction.amount.toString(), { thousandSeparator: true })} ${
                    transaction.currency
                  }`,
                ],
                [
                  "Reimbursement Amount",
                  `${numericFormatter(transaction.reimbursementAmount.toString(), {
                    decimalScale: 2,
                    thousandSeparator: true,
                  })} ${transaction.reimbursementCurrency} (conversion rate: ${transaction.currencyConversionRate})`,
                ],
                ["Comment", transaction.comment],
              ].map(([title, value], index) => (
                <View key={index} style={styles.tableRow}>
                  <View style={styles.leftTableCell}>
                    <Text>{title}</Text>
                  </View>
                  <View style={styles.rightTableCell}>
                    <Text style={title === "Expense Type" || title === "Comment" ? styles.lineHeightAdjustedText : {}}>
                      {value}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          ))}
        </View>
      </Page>
      <Page style={styles.page}>
        {receipts.map((receipt, index) => {
          if (receipt && receipt.pages.length > 0) {
            return (
              <View key={index} break={index != 0 ? true : false} style={styles.imgBody}>
                <Text style={styles.receiptHeader}>Claim Item {index + 1} Receipt</Text>
                {receipt.pages.map((page, pageIndex) => (
                  <Image key={pageIndex} src={page} />
                ))}
              </View>
            );
          }
        })}
      </Page>
    </Document>
  );
};
