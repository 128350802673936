import { createBrowserRouter } from "react-router-dom";
import { Root } from "./pages/Root";
import { NewClaim } from "./pages/NewClaim";
import { ClaimHistory } from "./pages/ClaimHistory";
import { Approvals } from "./pages/Approvals";
import { View } from "./utils/types";
import { NotFound } from "./pages/NotFound";

export const router = (accessibleViews: View[]) => {
  const baseRoutes = [
    { path: "new-claim", element: <NewClaim /> },
    { path: "claim-history", element: <ClaimHistory /> },
  ];

  if (accessibleViews.includes(View.LEAD)) {
    baseRoutes.push({ path: "lead-approvals", element: <Approvals key="lead-approvals" view={View.LEAD} /> });
  }

  if (accessibleViews.includes(View.FINANCE)) {
    baseRoutes.push({ path: "finance-approvals", element: <Approvals key="finance-approvals" view={View.FINANCE} /> });
  }

  return createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      children: baseRoutes,
      errorElement: <NotFound />,
    },
  ]);
};
