import { ReactElement, cloneElement, useEffect, useState } from "react";
import { List, ListItemButton, ListItemText, Drawer, Box, Typography, ListItemIcon } from "@mui/material";
import AddIcon from "@mui/icons-material/AddBoxOutlined";
import HistoryIcon from "@mui/icons-material/History";
import LeadIcon from "@mui/icons-material/Person4Outlined";
import FinanceIcon from "@mui/icons-material/Groups3Outlined";
import { useLocation, useNavigate } from "react-router-dom";
import { resetFilters } from "../store/slices/filterSlice";
import { resetTableData } from "../store/slices/tableSlice";
import { useAppDispatch, useAppSelector } from "../store";
import { areArraysEqual } from "../utils/utils";
import { removeAllResubmitClaimItems, resetClaimDetails } from "../store/slices/claimDetailsSlice";
import { ConfirmationDialog } from "./ConfirmationDialog";
import { View } from "../utils/types";
import { sideBarWidth } from "../utils/constants";

enum TabLabels {
  NEW_CLAIM = "New Claim",
  CLAIM_HISTORY = "Claim History",
  LEAD_APPROVALS = "Lead Approvals",
  FINANCE_APPROVALS = "Finance Approvals",
}

export const Sidebar = () => {
  const selectedClaim = useAppSelector((state) => state.claimDetails.selectedClaim);
  const resubmitClaimItems = useAppSelector((state) => state.claimDetails.resubmitClaimItems);
  const accessibleViews = useAppSelector((state) => state.appData.accessibleViews);

  const [selectedTab, setSelectedTab] = useState<keyof typeof TabLabels>();
  const [tempSelectedTab, setTempSelectedTab] = useState<keyof typeof TabLabels | null>(null);
  const [openDiscardConfirmation, setOpenDiscardConfirmation] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const tabUrls: Record<keyof typeof TabLabels, string> = {
    NEW_CLAIM: "/new-claim",
    CLAIM_HISTORY: "/claim-history",
    LEAD_APPROVALS: "/lead-approvals",
    FINANCE_APPROVALS: "/finance-approvals",
  };

  const iconMapping: Record<keyof typeof TabLabels, ReactElement> = {
    NEW_CLAIM: <AddIcon />,
    CLAIM_HISTORY: <HistoryIcon />,
    LEAD_APPROVALS: <LeadIcon />,
    FINANCE_APPROVALS: <FinanceIcon />,
  };

  const handleSidebarClick = (tab: keyof typeof TabLabels) => {
    if (selectedTab !== tab) {
      if (
        resubmitClaimItems.length === 0 ||
        (selectedClaim && areArraysEqual(selectedClaim.transactions, resubmitClaimItems))
      ) {
        dispatch(resetClaimDetails());
        dispatch(resetFilters());
        dispatch(resetTableData());
        navigate(tabUrls[tab]);
      } else {
        setTempSelectedTab(tab);
        setOpenDiscardConfirmation(true);
      }
    }
  };

  useEffect(() => {
    const currentTab = Object.keys(tabUrls).find((tab) => tabUrls[tab as keyof typeof TabLabels] === location.pathname);
    setTempSelectedTab(null);
    setSelectedTab(currentTab as keyof typeof TabLabels);
  }, [location.pathname]);

  return (
    <>
      <Drawer
        sx={{
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: sideBarWidth,
            boxSizing: "border-box",
            bgcolor: "white",
            zIndex: 0,
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box height={"80px"} />
        <List>
          {(Object.keys(TabLabels) as Array<keyof typeof TabLabels>).map((tab, index) => {
            const isSelected = selectedTab === tab;
            if (
              (TabLabels[tab] === TabLabels.LEAD_APPROVALS && !accessibleViews.includes(View.LEAD)) ||
              (TabLabels[tab] === TabLabels.FINANCE_APPROVALS && !accessibleViews.includes(View.FINANCE))
            ) {
              return null;
            }
            return (
              <ListItemButton
                key={index}
                selected={isSelected}
                onClick={() => handleSidebarClick(tab)}
                sx={{
                  "&.Mui-selected, &.Mui-selected:hover": {
                    backgroundColor: isSelected ? "primary.light" : "inherit",
                    color: isSelected ? "primary.main" : "text.primary",
                  },
                  marginX: "10px",
                  borderRadius: "4px",
                }}
              >
                <ListItemIcon sx={{ minWidth: "40px" }}>
                  {cloneElement(iconMapping[tab], {
                    sx: {
                      color: isSelected ? "primary.main" : "text.primary",
                      fontSize: "25px",
                    },
                  })}
                </ListItemIcon>
                <ListItemText>
                  <Typography fontSize={15} fontWeight={isSelected ? "600" : "500"}>
                    {TabLabels[tab]}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            );
          })}
        </List>
      </Drawer>

      <ConfirmationDialog
        isOpen={openDiscardConfirmation}
        dialogTitle="Edit Discard Confirmation"
        dialogContent="Your edit changes will be discarded"
        confirmButtonLabel="Confirm"
        handleConfirm={() => {
          setOpenDiscardConfirmation(false);
          dispatch(removeAllResubmitClaimItems());
          dispatch(resetFilters());
          dispatch(resetTableData());
          navigate(tabUrls[tempSelectedTab!]);
        }}
        handleClose={() => {
          setOpenDiscardConfirmation(false);
        }}
      />
    </>
  );
};
