import { Color, createTheme } from "@mui/material";
import "./app.css";

declare module "@mui/material/styles" {
  interface Palette {
    border: Palette["primary"];
    disable: Palette["primary"];
    cardBackground: Palette["primary"];
    blue: Partial<Color>;
  }
  interface PaletteOptions {
    border: PaletteOptions["primary"];
    cardBackground: PaletteOptions["primary"];
    disable: PaletteOptions["primary"];
    blue: Partial<Color>;
  }
}

export const theme = createTheme({
  palette: {
    mode: "light",
    grey: {
      "500": "#8EA3B5",
      "600": "#7C90A0",
      "900": "#6E7F8D",
    },
    disable: {
      main: "#EEEEEE",
      dark: "#BDBDBD",
    },
    blue: {
      "500": "#1B75D0",
    },
    primary: {
      main: "#FF7300",
      light: "#FFEDDA",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#292E3C",
      light: "#FDFDFD",
    },
    text: {
      primary: "#5D7285",
      secondary: "#292E3C",
    },
    success: {
      main: "#63C3AC",
      light: "#DEF5F0",
      "300": "#C1E8DF",
      contrastText: "white",
    },
    warning: {
      main: "#E0AB3C",
      light: "#F8F1E3",
      "300": "#F1E7D5",
    },
    error: {
      main: "#EC7474",
      dark: "#C25F5F",
      light: "#FCEBEB",
      "300": "#F8D7D7",
      contrastText: "white",
    },
    border: {
      main: "#E7E7E7",
      dark: "#3C3C41",
    },
    cardBackground: {
      main: "#F3F6F9",
    },
  },
  typography: {
    fontFamily: "Montserrat, sans-serif",
  },
  components: {
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: 14,
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          fontSize: 14,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        FormHelperTextProps: {
          style: {
            marginLeft: "5px",
            marginRight: "5px",
            fontSize: 10,
          },
        },
      },
    },
  },
});
